import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IProduct } from '../../interfaces/product/product';
// import Image from 'next/legacy/image';
import Link from 'next/link';
import Image from 'next/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as StartSolid } from '@fortawesome/free-solid-svg-icons';
import { faStar as StartHollow } from '@fortawesome/free-regular-svg-icons';
import { useProductSetFavourite } from '../../hooks/api/gql/products/useProductSetFavourite';
import { useProductRemoveFavourite } from '../../hooks/api/gql/products/useProductRemoveFavourite';
import { Transition } from '@headlessui/react';
import { useNavigationHandler } from '../Header/hooks/useNavigationHandler';

interface IProductDisplay {
    product: IProduct;
    color?: 'primary' | 'white';
    index?: number;
}

export const ProductDisplay: React.FC<IProductDisplay> = (props) => {
    const { product, color, index } = props;

    const { mutate: favor } = useProductSetFavourite();
    const { mutate: unFavor } = useProductRemoveFavourite();

    const { resetNavigation } = useNavigationHandler();

    const href = useMemo(() => {
        // This is the new version
        return `/productoverview/${product.id}`;

        // This is the Legacy Version
        // return `ProductDetails.php?product=${product.id}&category=${product.category?.id ?? '0'}`;
    }, [product.id]);

    const colorUsed = useMemo(() => {
        switch (color) {
            case 'primary':
                return 'text-primary';
            case 'white':
                return 'text-neutral-100';
        }

        return 'bg-neutral-500';
    }, [color]);

    const toggleFavourite = useCallback(() => {
        if (product.favourite) {
            unFavor({ id: Number(product.id) });
        } else {
            favor({ id: Number(product.id) });
        }
    }, [favor, product.favourite, product.id, unFavor]);

    const [show, setShow] = useState(false);

    /**
     * The idea of this function is to move the eyecatchers one-after-another into the scene
     */
    useEffect(() => {
        if (index) {
            const t = 250 * index;

            setTimeout(() => {
                setShow(true);
            }, t);
        }
    }, [index]);

    return (
        <div className="group relative flex h-max w-[256px] flex-col gap-0 overflow-x-hidden transition-all hover:scale-105">
            <Link
                href={href}
                className="flex h-max w-full cursor-pointer flex-col gap-1 overflow-x-hidden"
                onClick={() => resetNavigation()}
            >
                <Transition
                    as={'div'}
                    className="absolute top-0 left-0 z-10 flex flex-row bg-primary"
                    style={{ backgroundColor: product.fields?.eyeCatcherColour ?? '' }}
                    show={show}
                    appear={true}
                    key={`product-eyecatchter-label${product.id}`}
                    enter={`transition ease-out duration-1000`}
                    enterFrom="opacity-0 -translate-x-20"
                    enterTo="opacity-100 translate-x-0"
                >
                    {product.fields?.eyeCatcherText && (
                        <span
                            className="px-2 py-1 font-medium text-sm text-white"
                            dangerouslySetInnerHTML={{
                                __html: product.fields.eyeCatcherText,
                            }}
                        />
                    )}
                </Transition>

                <Image
                    src={product.thumbnail ?? ''}
                    width={256}
                    height={309}
                    alt={product.translation}
                    placeholder="blur"
                    // https://png-pixel.com/
                    blurDataURL="/shop/images/blur/1x1-dcdcdc51.png"
                    priority={true}
                />

                <div className={`font-medium text-lg ${colorUsed}`}>
                    {product.translation ?? product.name ?? '-'}
                </div>
            </Link>
            {/* Favourite Button */}
            <button
                className={`absolute top-1 right-2 flex aspect-[1/1] h-8 w-8 items-center justify-center rounded-full transition-all ${
                    product.favourite ? 'bg-secondary/75' : 'bg-primary-light/50 hover:bg-primary'
                }`}
                onClick={() => toggleFavourite()}
            >
                <FontAwesomeIcon
                    icon={product.favourite ? StartSolid : StartHollow}
                    className="text-base text-neutral-100"
                />
            </button>
        </div>
    );
};

ProductDisplay.defaultProps = {
    color: 'primary',
    index: 1,
};

import { atom, selector } from 'recoil';
import { nanoid } from 'nanoid';

interface IIWIRecoilState {
    show: boolean;
    firstname: string;
    lastname: string;
    email: string;
    image: null | File;
    DSVGO_Contract: string | null;
}

export const iwiStateTransparentRecoil = atom<IIWIRecoilState>({
    key: `iwi-state-transparent`,
    default: {
        show: false,
        image: null,
        lastname: '',
        firstname: '',
        email: '',
        DSVGO_Contract: null,
    },
});

export const getDSVGOContract = selector<string | null>({
    key: `/${nanoid()}`,
    get: ({ get }): string | null => {
        return get(iwiStateTransparentRecoil).DSVGO_Contract;
    },
});

import React from 'react';
import { withBasePath } from '../../../../../helper/getBasePath';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { TextFieldNew } from '../../../../forms/TextFieldNew';
import { useRecoilValue } from 'recoil';
import { getDSVGOContract } from '../../../../IWI/recoil/iwiStateTransparent';
import { useUser } from '../../../../../hooks/api/gql/user/queries/useUser';
import { useAllLocales } from '../../../../../hooks/locale/useAllLocales';

export interface IStage5 {}

export const Stage5: React.FC<IStage5> = () => {
    const dsvgo = useRecoilValue(getDSVGOContract);

    const { data: userData } = useUser();

    const locales = useAllLocales();

    return (
        <div className="relative flex h-max w-full flex-col gap-2">
            {/* Grid Component */}
            <div className="relative grid h-max w-full grid-cols-[247px,504px,auto] gap-4">
                {/* Camera Icon */}
                <div className="relative flex aspect-[247/247] w-full flex-col items-center justify-center overflow-hidden">
                    <img
                        src={withBasePath('/images/iwi/FormIcon.png')}
                        alt="icon"
                        className="h-full w-full object-cover"
                    />
                </div>

                {/* Form */}
                <div className="relative flex flex-col justify-between">
                    <TextFieldNew
                        label={locales?.['LL_Profil_FirstName'] ?? 'First Name'}
                        required={true}
                        onChange={() => {}}
                        defaultValue={userData?.fields['UserProfileFirstName'] ?? ''}
                    />
                    <TextFieldNew
                        label={locales?.['LL_Profil_LastName'] ?? 'Last Name'}
                        required={true}
                        onChange={() => {}}
                        defaultValue={userData?.fields['UserProfileFirstName'] ?? ''}
                    />
                    <TextFieldNew
                        iconStart={faEnvelope}
                        label={locales?.['LL_Profil_EmailAddress'] ?? 'E-Mail'}
                        required={true}
                        validation={'email'}
                        onChange={() => {}}
                        defaultValue={userData?.email ?? ''}
                    />
                </div>

                {/* Text */}
                <div className="relative flex flex-col gap-4 text-black">
                    <p
                        dangerouslySetInnerHTML={{
                            __html: locales?.['LL_OnBoarding_Popup_7a_Copy'] ?? '',
                        }}
                    />
                    <p
                        dangerouslySetInnerHTML={{
                            __html: locales?.['LL_OnBoarding_Popup_7b_Copy'] ?? '',
                        }}
                    />
                </div>
            </div>

            {/* Lower Text */}
            <span className="mt-4 h-max text-xs text-black">{dsvgo}</span>
        </div>
    );
};

import { useProductsAll } from './api/gql/products/useProductsAll';
import { useMemo } from 'react';

export const useFavouriteProducts = () => {
    const { data: products, isLoading } = useProductsAll();

    return {
        favourites: useMemo(
            () => products?.filter((product) => product.favourite) ?? [],
            [products]
        ),
        isLoading,
    };
};

import React, {
    DetailedHTMLProps,
    HTMLAttributes,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useEvent } from 'react-use';
import Waiter from '../../Waiter/Waiter';
import { useApiRefresh } from '../../../hooks/api/useApiRefresh';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { apiState } from '../../../recoil/request';
import { nanoid } from 'nanoid';
import { addIFramed } from '../../../helper/addIFramed';
import { ubootStateRecoil } from './recoil/ubootState';
import { isJSON } from '../../../helper/isJson';
import { useQueryClient } from '@tanstack/react-query';
import { useClearUserCache } from '../../../hooks/api/gql/user/mutations/useClearUserCache';
import { useRouter, useSearchParams } from 'next/navigation';
import { sceneState } from '../../Scenes/recoil/sceneState';

interface IUbootIframe {
    src: string;
    inModal?: boolean;
    isFormFilling?: boolean;
}

interface IResponseData {
    command: string;
    location: string;
    height?: number;
    apiName?: string;
}

export const UbootIframe: React.FC<IUbootIframe> = (props) => {
    const { src, inModal, isFormFilling: isFormFillingHeader } = props;

    const [isFormFilling, setIsFormFilling] = useState(isFormFillingHeader);

    const { refresh } = useApiRefresh();

    const { mutate: clearUserCache } = useClearUserCache();

    const setSceneState = useSetRecoilState(sceneState);

    const queryClient = useQueryClient();

    // TODO Sollte eigentlich immer der gleiche key sein, wegen Rendering und Performance
    const [iFrameKey, setIFrameKey] = useState('uboot-iframe');

    const { isRefreshing } = useRecoilValue(apiState);

    const [wasRefreshing, setWasRefreshing] = useState(false);

    const srcWasSet = useRef(false);

    const [srcState, setSrcState] = useState<string>();

    const [loadingState, setLoadingState] = useState<null | boolean>(null);

    const router = useRouter();
    const queryParams = useSearchParams();

    const preRefreshSrc = useRef<string>();

    const ubootRef = useRef<HTMLIFrameElement | null>(null);

    const setUbootState = useSetRecoilState(ubootStateRecoil);

    const [stylesIframe, setStylesIframe] = useState<
        DetailedHTMLProps<HTMLAttributes<HTMLIFrameElement>, HTMLIFrameElement>
    >({});

    useEffect(() => {
        if (ubootRef) {
            setUbootState({ ref: ubootRef.current });
        }
    }, [setUbootState]);

    //Nachricht handler, hier wird primär die höhe und der loadingstate gesetzt, falls sich die location geändert hat wird auch der uBootPage Parameter an die url gehangen
    useEvent('message', (event: MessageEvent) => {
        if (event.data && isJSON(event.data as string)) {
            const data = JSON.parse(event.data as string) as IResponseData;

            switch (data.command) {
                case 'moxieLoaded':
                case 'loaded':
                    if (data.location && data.location !== srcState) {
                        if (data.location.toLowerCase().includes('/login')) {
                            if (!isRefreshing) {
                                //Refresh time!

                                const locationParams = new URLSearchParams(
                                    new URL(data.location).search
                                );

                                const successUrl = locationParams.get('SuccessUrl');

                                if (successUrl) {
                                    preRefreshSrc.current = `${
                                        process.env['NEXT_PUBLIC_SHOP_URL'] ?? ''
                                    }${
                                        successUrl.startsWith('/')
                                            ? successUrl
                                            : decodeURIComponent(
                                                  Buffer.from(successUrl, 'base64').toString(
                                                      'utf-8'
                                                  )
                                              )
                                    }`;
                                } else {
                                    preRefreshSrc.current = src;
                                }
                                void refresh();
                            }
                        } else {
                            if (
                                data.location.endsWith('home.php') ||
                                data.location.endsWith('content.php?category=21')
                            ) {
                                router.push('/home/');
                            } else if (window !== undefined) {
                                if (data.location.includes('Customize.php?docid=')) {
                                    setSceneState({ footer: false, noHeader: true });
                                    setIsFormFilling(true);
                                } else {
                                    setSceneState({ footer: true, noHeader: false });
                                    setIsFormFilling(false);
                                }
                                window.history.replaceState(
                                    null,
                                    '',
                                    `?uBootPage=${encodeURIComponent(
                                        Buffer.from(data.location, 'utf-8').toString('base64')
                                    )}`
                                );
                                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                                setLoadingState(true);
                            }
                        }
                    } else {
                        setLoadingState(true);
                    }
                    break;
                case 'loading':
                    setLoadingState(false);

                    window.scrollTo(0, 0);
                    break;
                case 'resizing':
                    if (data.height && !isFormFilling) {
                        setStylesIframe({
                            height: `${String(data.height)}px`,
                        } as DetailedHTMLProps<HTMLAttributes<HTMLIFrameElement>, HTMLIFrameElement>);
                    }
                    break;
                case 'redirect':
                    if (data.location) {
                        router.push(data.location);
                    }
                    break;
                case 'invalidateApiCache':
                    if (data.apiName) {
                        if (data.apiName === 'user') {
                            clearUserCache(undefined, {
                                onSuccess: () => {
                                    void queryClient.invalidateQueries([data.apiName]);
                                },
                            });
                        } else {
                            void queryClient.invalidateQueries([data.apiName]);
                        }
                    }
                    break;
            }
        }
    });

    //iFrame src workflow, unterscheidung zwischen firststart und ggf. den url parameter nehmen,
    // ansonsten die plain src - falls man sich auf login befindet wird der refresh workflow gestartet
    useEffect(() => {
        if (srcWasSet.current) {
            if (!srcState && !preRefreshSrc.current) {
                setSrcState(addIFramed(src));
            } else if (
                srcState &&
                src &&
                !src.startsWith('undefined') &&
                srcState !== addIFramed(src)
            ) {
                setSrcState(addIFramed(src));
            }
        } else {
            if (queryParams.has('uBootPage')) {
                const toTry = Buffer.from(
                    decodeURIComponent(queryParams.get('uBootPage') as string),
                    'base64'
                ).toString('utf-8');
                if (toTry !== srcState) {
                    srcWasSet.current = true;
                    if (toTry.endsWith('home.php') || toTry.endsWith('content.php?category=21')) {
                        router.push('/home/');
                    } else {
                        setSrcState(addIFramed(toTry));
                    }
                }
            } else if (!srcState) {
                srcWasSet.current = true;
                setSrcState(addIFramed(src));
            }
        }
    }, [loadingState, queryParams, refresh, router, src, srcState]);

    //Schauen ob refreshen gstartet wurde
    useEffect(() => {
        if (!wasRefreshing && isRefreshing) {
            setWasRefreshing(true);
        }
    }, [isRefreshing, wasRefreshing]);

    //Src setzen nach refreshen
    useEffect(() => {
        if (wasRefreshing && !isRefreshing && preRefreshSrc.current) {
            setSrcState(addIFramed(preRefreshSrc.current));
            setWasRefreshing(false);
            setIFrameKey(nanoid());
        }
    }, [isRefreshing, wasRefreshing]);

    const height = useMemo(() => {
        if (inModal) return 'h-[calc(100vh-4rem)] max-h-[600px] max-w-[1060px]';
        if (isFormFilling) return 'h-screen';

        return 'h-[calc(100vh-6rem)]';
    }, [inModal, isFormFilling]);

    const srcToUse = useMemo(() => {
        if (!srcState) {
            return srcState;
        }

        let tempUrl = srcState;

        if (tempUrl.startsWith('http:')) {
            tempUrl = tempUrl.replace('http:', 'https:');
        }

        return tempUrl;
    }, [srcState]);

    useEffect(() => {
        return () => {
            setSceneState({});
        };
    }, [setSceneState]);

    return (
        <div className={'h-min'}>
            {loadingState !== true && (
                <div
                    className={`relative flex w-full flex-col items-center justify-center ${height}`}
                >
                    <Waiter />
                </div>
            )}

            <iframe
                ref={ubootRef}
                src={srcToUse}
                key={iFrameKey}
                style={isFormFilling ? {} : { ...stylesIframe }}
                className={`relative z-0 ${
                    loadingState === true ? 'block' : 'opacity-0'
                } w-screen ${height}`}
            />
        </div>
    );
};

UbootIframe.defaultProps = {
    isFormFilling: false,
};

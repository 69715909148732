import { useMutation, useQueryClient } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useApi } from '../../../useApi';
import { IUserFromDB } from '../../../../../interfaces/IUser';
import { mapUserFromDbToFe } from '../mapUserFromDbToFe';

export const useUserChangeLanguage = () => {
    const { postGql } = useApi();

    const queryClient = useQueryClient();

    return useMutation(
        async (input: { language: string }) => {
            const { changeLanguage } = await postGql<{ changeLanguage: IUserFromDB }>(
                gql`
                    mutation ($input: ChangeLanguageInput!) {
                        changeLanguage(input: $input) {
                            id
                            userName
                            email
                            language
                            exemptFromVat
                            thumbnail
                            fields {
                                name
                                value
                            }
                            groups
                        }
                    }
                `,
                { input },
                'changeLanguage'
            );
            return mapUserFromDbToFe(changeLanguage);
        },
        {
            onSuccess: async () => {
                return queryClient.invalidateQueries();
            },
        }
    );
};

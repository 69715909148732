import React from 'react';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IButtonRound {
    icon: IconDefinition;
    onClick: () => void;
    clean?: boolean;
    animate?: boolean;
}

export const ButtonRound: React.FC<IButtonRound> = (props) => {
    const { icon, onClick, clean, animate } = props;
    return (
        <>
            <button
                className={`group relative flex h-8 w-8 items-center justify-center rounded-full ${
                    animate ? 'animate-spin' : ''
                } ${
                    clean ? 'bg-transparent' : 'bg-neutral-500'
                } p-2 opacity-100 transition hover:bg-primary-light`}
                onClick={() => onClick()}
            >
                <FontAwesomeIcon
                    icon={icon}
                    className={`${
                        clean ? 'text-neutral-500 group-hover:text-white' : 'text-white'
                    }`}
                />
            </button>
        </>
    );
};

ButtonRound.defaultProps = {
    clean: false,
    animate: false,
};

import { Transition } from '@headlessui/react';
import React, { useEffect, useState } from 'react';
import { getBasePath } from '../../helper/getBasePath';
import { useInterval } from 'react-use';

export const Waiter: React.FC = () => {
    const [startBalloon, setStartBalloon] = useState(false);

    const [startSkyline, setStartSkyline] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setStartBalloon(true);
        });
    }, []);

    useInterval(() => {
        setStartSkyline(!startSkyline);
    }, 2000);

    return (
        <>
            <figure className="relative flex h-52 w-full flex-col gap-4">
                <div className="relative flex w-full items-center justify-center">
                    <Transition
                        show={startBalloon}
                        appear={true}
                        className="absolute -top-12"
                        enter="transition ease-out duration-[3000ms] delay-200"
                        enterFrom="transform opacity-0 translate-y-10"
                        enterTo="transform opacity-100 translate-y-0"
                    >
                        {/* eslint-disable-next-line @next/next/no-img-element */}
                        <img
                            className="animationHoverUpDown aspect-[1/1] h-full h-24 opacity-50"
                            src={`${getBasePath()}/remaxBalloon.svg`}
                            alt="logo RE/MAX Marketing Center"
                            aria-hidden={true}
                        />
                    </Transition>
                </div>
                <div className="relative top-4 flex h-32 w-full flex-row items-center justify-center gap-4">
                    <div className="animationHoverLeftToRight flex w-max flex-row flex-nowrap">
                        {/* eslint-disable-next-line @next/next/no-img-element */}
                        <img
                            className="relative left-5 max-h-96 w-max opacity-50"
                            src={`${getBasePath()}/waiter/noun-chengdu-3583753-D3D3D3.svg`}
                            alt="logo RE/MAX Marketing Center"
                            aria-hidden={true}
                        />
                        {/* eslint-disable-next-line @next/next/no-img-element */}
                        <img
                            className="relative right-5 max-h-96 w-max opacity-50"
                            src={`${getBasePath()}/waiter/noun-chengdu-3583753-D3D3D3.svg`}
                            alt="logo RE/MAX Marketing Center"
                            aria-hidden={true}
                        />
                    </div>
                </div>
            </figure>
        </>
    );
};
export default Waiter;

import React, { useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { navigationShopAtom } from './recoil/shopNavigation';
import Link from 'next/link';
import { nanoid } from 'nanoid';
import ModalFullscreen from '../Modal/ModalFullscreen';
import { useNavigation } from '../../hooks/api/gql/navigation/useNavigation';
import Image from 'next/legacy/image';
import { useNavigationHandler } from './hooks/useNavigationHandler';
import { UserContextButton } from '../UserContextMenu/UserContextButton';
import { faList, faStar, faTree } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import { useAllLocales } from '../../hooks/locale/useAllLocales';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';

interface LinkLabel {
    link: string;
    label: string;
}

interface NavItem extends LinkLabel {
    children: LinkLabel[];
}

export const ShopNavigation: React.FC = () => {
    const [navState, setNavState] = useRecoilState(navigationShopAtom);

    const featureFlags = useFeatureFlags();

    const { data: navigation } = useNavigation();
    const { resetNavigation } = useNavigationHandler();

    const locales = useAllLocales();

    const mainAndSubItems = useMemo<NavItem[][]>(() => {
        const items: NavItem[][] = [];

        if (navigation && navigation.tabs && navigation.tabs[navState.tab]) {
            const columns = navigation.tabs[navState.tab]?.columns ?? [];
            columns.map((column, index) => {
                if (!items[index]) {
                    items[index] = [];
                }
                for (let x = 0; x < column.items.length; x++) {
                    if (column.items[x]?.mainCategory) {
                        const children: LinkLabel[] = [];
                        for (let y = x + 1; y < column.items.length; y++) {
                            if (column.items[y]?.mainCategory) {
                                break;
                            }
                            children.push({
                                link: `/category/${column.items[y]?.categoryID ?? ''}`,
                                label: column.items[y]?.label ?? '',
                            });
                        }
                        items[index]?.push({
                            link: `/category/${column.items[x]?.categoryID ?? ''}`,
                            label: column.items[x]?.label ?? '',
                            children,
                        });
                    }
                }
            });
        }

        return items;
    }, [navState.tab, navigation]);

    const getChristmasWithYear = useMemo(() => {
        const baseString = locales?.['LL_Navi_Subjects_Seasonal_Christmas'] ?? 'Christmas';

        const year = dayjs().year();

        return `${baseString} ${year}`;
    }, [locales]);

    return (
        <>
            <ModalFullscreen
                show={navState.showShop}
                closeFunction={() => setNavState({ ...navState, showShop: false })}
            >
                {navigation && (
                    <>
                        <section className="relative mx-auto mt-8 w-full max-w-7xl grid-rows-[100px,auto] flex-col pl-6 pr-2 pt-2 md:pl-0 md:pr-0">
                            {/*Desktop Navigation*/}
                            <div className="relative flex hidden w-full flex-row justify-center gap-8 lg:flex">
                                {navigation.tabs.map((tab, index) => {
                                    return (
                                        <button
                                            key={`tab-${index}`}
                                            className={`flex w-max items-center justify-center border-b px-4 py-2 font-semibold transition-all lg:text-base ${
                                                navState.tab === index
                                                    ? 'border-white text-white'
                                                    : 'border-transparent text-neutral-50/75 hover:border-neutral-300/50'
                                            } ${
                                                tab.title === 'Commercial'
                                                    ? 'bg-commercial'
                                                    : tab.title === 'Collection'
                                                    ? 'bg-collection'
                                                    : ''
                                            }`}
                                            onClick={() => setNavState({ ...navState, tab: index })}
                                        >
                                            {tab.label}
                                        </button>
                                    );
                                })}
                            </div>

                            {/*Mobile Navigation*/}
                            <div className="relative flex w-full items-center justify-center lg:hidden">
                                <select
                                    className="relative w-9/12 bg-transparent text-white"
                                    onChange={(e) => {
                                        setNavState({ ...navState, tab: Number(e.target.value) });
                                    }}
                                    value={navState.tab}
                                >
                                    {navigation.tabs.map((tab, index) => {
                                        return (
                                            <option key={`tab-${index}${nanoid()}`} value={index}>
                                                {tab.label}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>

                            <div className="relative mt-4 flex h-full w-full flex-col items-center gap-1 md:flex-row md:items-start lg:max-h-[700px]">
                                {mainAndSubItems.map((column) => {
                                    return (
                                        <div className={'w-full md:w-1/4'} key={nanoid()}>
                                            {column.map((mainItem) => {
                                                return (
                                                    <div
                                                        key={nanoid()}
                                                        className={`relative ${
                                                            mainItem.children.length
                                                                ? 'mt-10'
                                                                : 'mt-6'
                                                        } flex w-full flex-col items-start`}
                                                    >
                                                        {/*Sub Title*/}
                                                        <Link
                                                            onClick={resetNavigation}
                                                            href={mainItem.link}
                                                            className="flex w-full items-start text-left text-base font-semibold text-primary-light"
                                                        >
                                                            {mainItem.label}
                                                        </Link>

                                                        {/*Items*/}
                                                        <div className="relative mt-2 flex w-full flex-col gap-1 border-l border-white pl-4">
                                                            {mainItem.children.map((subItem) => {
                                                                return (
                                                                    <Link
                                                                        onClick={resetNavigation}
                                                                        href={subItem.link ?? ''}
                                                                        key={nanoid()}
                                                                        className="text-base font-semibold text-neutral-50"
                                                                    >
                                                                        {subItem.label}
                                                                    </Link>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    );
                                })}
                                {navigation.tabs[navState.tab]?.image && (
                                    <div className={'mt-10 flex hidden w-1/4 flex-col md:block'}>
                                        <div className={'relative h-[250px]'}>
                                            <Image
                                                src={navigation.tabs[navState.tab]?.image ?? ''}
                                                alt={'Image'}
                                                layout={'fill'}
                                            />
                                        </div>
                                        <div className={'mt-5'}>
                                            <span className={'text-sm text-white'}>
                                                {navigation.tabs[navState.tab]?.copyText}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </section>

                        {locales && (
                            <section className="relative mx-auto mt-4 flex w-full max-w-7xl flex-row gap-14 bg-white/10 px-2 py-2 shadow-xl backdrop-blur-sm">
                                <div className="w-max">
                                    <UserContextButton
                                        icon={faList}
                                        label={locales['LL_Footer_Alle_Produkte'] ?? 'All Products'}
                                        href={'/category/49'}
                                        onClick={() => resetNavigation()}
                                    />
                                </div>
                                <div className="w-max">
                                    <UserContextButton
                                        icon={faStar}
                                        label={
                                            locales['LL_Navi_Subjects_Seasonal'] ?? 'New Products'
                                        }
                                        href={'/category/185'}
                                        onClick={() => resetNavigation()}
                                    />
                                </div>
                                {!featureFlags ||
                                    (featureFlags.SHOW_XMAS && (
                                        <div className="w-max">
                                            <UserContextButton
                                                icon={faTree}
                                                label={getChristmasWithYear}
                                                href={'/category/284'}
                                                onClick={() => resetNavigation()}
                                            />
                                        </div>
                                    ))}
                            </section>
                        )}
                    </>
                )}
            </ModalFullscreen>
        </>
    );
};

import React from 'react';
import { withBasePath } from '../../../../../helper/getBasePath';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Image from 'next/image';
import { useAllLocales } from '../../../../../hooks/locale/useAllLocales';

export interface IStage3 {}

export const Stage3: React.FC<IStage3> = () => {
    const locales = useAllLocales();

    return (
        <div className="relative grid h-max w-full grid-cols-[192px,20px,192px,20px,389px,auto] gap-2">
            {/* Image with Background */}
            <div className="relative flex aspect-[192/247] w-full flex-col items-center justify-center overflow-hidden">
                <Image
                    src={withBasePath('/icons/profile/PortraitbildService-Portrait-BG.svg')}
                    alt="icon"
                    className="h-full w-full object-cover"
                    width={192}
                    height={247}
                />
            </div>

            {/* Chevron Icon */}
            <div className="relative flex flex-col items-center justify-center ">
                <FontAwesomeIcon icon={faChevronRight} className="text-xs text-primary-light" />
            </div>

            {/* Image */}
            <div className="relative flex aspect-[192/247]  w-full flex-col items-center justify-center overflow-hidden">
                <Image
                    src={withBasePath('/icons/profile/PortraitbildService-Portrait.svg')}
                    alt="icon"
                    className="h-full w-full object-cover"
                    width={192}
                    height={247}
                />
            </div>

            {/* Chevron Icon */}
            <div className="relative flex flex-col items-center justify-center ">
                <FontAwesomeIcon icon={faChevronRight} className="text-xs text-primary-light" />
            </div>

            {/* Visitenkarte */}
            <div className="relative flex aspect-[389/247] w-full flex-col items-center justify-center overflow-hidden">
                <Image
                    src={withBasePath('/icons/profile/PortraitbildService-Visitenkarte.svg')}
                    alt="icon"
                    className="h-full w-full object-cover"
                    width={389}
                    height={247}
                />
            </div>

            {/* Text */}
            <div className="relative flex flex-col justify-start leading-5 text-black">
                <p
                    dangerouslySetInnerHTML={{
                        __html: locales?.['LL_OnBoarding_Popup_5b_Copy'] ?? '',
                    }}
                />
            </div>
        </div>
    );
};

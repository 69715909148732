import React from 'react';
import { withBasePath } from '../../../../../helper/getBasePath';
import { RmcLink } from '../../../../atoms/RmcLink/RmcLink';
import { useAllLocales } from '../../../../../hooks/locale/useAllLocales';

export interface IStage1 {}

export const Stage1: React.FC<IStage1> = () => {
    const locales = useAllLocales();

    return (
        <div className="relative grid h-max w-full grid-cols-[3fr,3fr,3fr,3fr] gap-2">
            <div className="relative flex aspect-[264/240] w-full flex-col items-center justify-center overflow-hidden">
                <img
                    src={withBasePath('/images/iwi/01-remax-pbservice-illu-01.svg')}
                    alt="icon"
                    className="h-full w-full object-cover"
                />
            </div>
            <div className="relative flex flex-col justify-start gap-4 text-black">
                <p
                    dangerouslySetInnerHTML={{
                        __html: locales?.['LL_OnBoarding_Popup_1_Copy'] ?? '',
                    }}
                />
            </div>
            <div className="relative flex aspect-[264/240] w-full flex-col items-center justify-center overflow-hidden">
                <img
                    src={withBasePath('/images/iwi/02-remax-pbservice-illu-01.svg')}
                    alt="icon"
                    className="h-full w-full object-cover"
                />
            </div>
            <div className="relative flex flex-col justify-between">
                <div className="relative flex flex-col justify-start gap-4 text-black">
                    <p
                        dangerouslySetInnerHTML={{
                            __html: locales?.['LL_OnBoarding_Popup_2_Copy'] ?? '',
                        }}
                    />
                </div>
                <div className="relative pb-3">
                    <RmcLink
                        href={
                            'https://www.marketingcenter.remax.eu/Custom/Downloads/HTML_Link_PDF_02_PortraitService/REMAX_Portrait_Briefing_de-DE.pdf'
                        }
                        target="_blank"
                        fontSize="text-base"
                        fontWeight="medium"
                    >
                        {locales?.['LL_OnBoarding_Popup_Download_PortraitBriefing'] ??
                            'Porträtfoto-Anleitung herunterladen'}
                    </RmcLink>
                </div>
            </div>
        </div>
    );
};

import React from 'react';
import { StageHeader } from './stageHeader/StageHeader';
import { Stage1 } from './stages/stage1/Stage1';
import { Stage2 } from './stages/stage2/Stage2';
import { Stage3 } from './stages/stage3/Stage3';
import { Stage4 } from './stages/stage4/Stage4';
import { Stage5 } from './stages/stage5/Stage5';
import { BottomNavigation } from './bottomNavigation/BottomNavigation';

export interface ITransparentPortrait {
    stages: number;
    currentStage: number;
    nextStage: () => void;
    previousStage: () => void;
    isSubmitting: boolean;
}

export const TransparentPortrait: React.FC<ITransparentPortrait> = (props) => {
    const { stages, currentStage, nextStage, previousStage, isSubmitting } = props;

    return (
        <div className="relative flex h-max min-h-[500px] w-full flex-col gap-2 p-10">
            <StageHeader stages={stages} currentStage={currentStage} />
            <div className="relative pt-8">
                {currentStage === 1 && <Stage1 />}
                {currentStage === 2 && <Stage2 />}
                {currentStage === 3 && <Stage3 />}
                {currentStage === 4 && <Stage4 />}
                {currentStage === 5 && <Stage5 />}
            </div>
            <BottomNavigation
                stages={stages}
                currentStage={currentStage}
                previousStage={previousStage}
                nextStage={nextStage}
                isSubmitting={isSubmitting}
            />
        </div>
    );
};

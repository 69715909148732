import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { Badge } from '../atoms/Badges/Badge';
import { useRecoilState } from 'recoil';
import { favouriteStateRecoil } from './recoil/favouriteState';
import { useNavigationHandler } from '../Header/hooks/useNavigationHandler';
import { useFavouriteProducts } from '../../hooks/useFavouriteProducts';

export const FavouriteButton: React.FC = () => {
    const [favouriteState, setFavouriteState] = useRecoilState(favouriteStateRecoil);
    const { resetNavigation } = useNavigationHandler();

    const openModal = useCallback(() => {
        resetNavigation();

        setFavouriteState({ ...favouriteState, showModal: !favouriteState.showModal });
    }, [favouriteState, resetNavigation, setFavouriteState]);

    const { favourites } = useFavouriteProducts();
    return (
        <>
            <button onClick={() => openModal()}>
                <div className="group relative flex aspect-[1/1] h-10 w-10 cursor-pointer items-center justify-center rounded-full transition-all hover:bg-neutral-100">
                    <FontAwesomeIcon icon={faStar} className="z-10 text-xl text-neutral-400" />
                    <div className="absolute -top-2 -right-2">
                        <Badge color="primary-light">{favourites.length}</Badge>
                    </div>
                </div>
            </button>
        </>
    );
};

import { atom } from 'recoil';
import { nanoid } from 'nanoid';

interface ISceneState {
    noHeader?: boolean;
    footer?: boolean;
    subheader?: boolean;
}

export const sceneState = atom<ISceneState>({
    key: `sceneState/${nanoid()}`,
    default: {},
});

import React from 'react';
import 'swiper/css';
import { useRecoilState } from 'recoil';
import { moxieStateRecoil } from './recoil/moxieState';
import { ModalFullscreenNeutral } from 'src/components/atoms/Modal/ModalFullscreenNeutral';
import { addIFramed } from '../../../helper/addIFramed';
import { UbootIframe } from '../../atoms/UbootIframe/UbootIframe';

export const MoxieModal: React.FC = () => {
    const [{ type, show }, setMoxieState] = useRecoilState(moxieStateRecoil);

    return (
        <>
            <ModalFullscreenNeutral
                show={show}
                closeable={true}
                afterClose={() =>
                    setMoxieState((currVal) => {
                        return { ...currVal, show: false };
                    })
                }
                noPadding={true}
            >
                <UbootIframe
                    src={
                        addIFramed(
                            `${
                                process.env['NEXT_PUBLIC_SHOP_URL'] ?? ''
                            }MoxieManager.php?MM_AssetMode=${type}`
                        ) ?? ''
                    }
                    inModal={true}
                />
            </ModalFullscreenNeutral>
        </>
    );
};

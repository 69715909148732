import React from 'react';
import Image from 'next/legacy/image';
import { getBasePath } from '../../../helper/getBasePath';

export const BackgroundBalloon: React.FC = () => {
    return (
        <>
            <figure className="absolute h-screen w-screen bg-primary" aria-hidden={true}>
                <Image
                    src={getBasePath() + `/images/backgrounds/Background-Baloon-Dark.jpg`}
                    alt="RE/MAX Background"
                    objectFit="cover"
                    layout="fill"
                    quality={100}
                />
            </figure>
        </>
    );
};

export const addIFramed = (src?: string): string | undefined => {
    if (!src) {
        return src;
    }
    try {
        const url = new URL(src);
        const searchParams = new URLSearchParams(url.search);
        searchParams.set('iframed', '1');
        return `${url.origin}${url.pathname}?${searchParams.toString()}`;
    } catch {
        return src;
    }
};

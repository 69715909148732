import React, { PropsWithChildren, useEffect, useMemo } from 'react';
import { AuthGuard } from '../Auth/AuthGuard';
import { Header } from '../Header/Header';
import Footer from '../molecules/Footer/Footer';
import { SearchModal } from '../SearchBar/SearchModal';
import { ShopNavigation } from '../Header/ShopNavigation';
import { TopicsNavigation } from '../Header/TopicsNavigation';
import { IWIModal } from '../IWI/IWIModal';
import { UserContextModal } from '../UserContextMenu/UserContextModal';
import { MoxieModal } from '../legacy/MoxieManager/MoxieModal';
import { FavouritesModal } from '../Favourites/FavouritesModal';
import { SubHeader } from '../Header/SubHeader';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { sceneState } from './recoil/sceneState';
import { ContactFormLegacy } from '../contactFormLegacy/ContactFormLegacy';
import { IWIModalTransparent } from '../IWI/IWIModalTransparent';

interface IScene extends PropsWithChildren {
    subheader?: boolean;
    footer?: boolean;
    noHeader?: boolean;
}

export const Scene: React.FC<IScene> = (props) => {
    const { subheader, footer, noHeader } = props;

    const sceneStateValue = useRecoilValue(sceneState);
    const setSceneState = useSetRecoilState(sceneState);

    const subHeaderActual = useMemo(() => {
        if (sceneStateValue.subheader !== undefined) {
            return sceneStateValue.subheader;
        }
        return subheader;
    }, [sceneStateValue.subheader, subheader]);

    const footerActual = useMemo(() => {
        if (sceneStateValue.footer !== undefined) {
            return sceneStateValue.footer;
        }
        return footer;
    }, [footer, sceneStateValue.footer]);

    const noHeaderActual = useMemo(() => {
        if (sceneStateValue.noHeader !== undefined) {
            return sceneStateValue.noHeader;
        }
        return noHeader;
    }, [noHeader, sceneStateValue.noHeader]);

    useEffect(() => {
        return () => {
            setSceneState({});
        };
    }, [setSceneState]);

    return (
        <>
            <AuthGuard>
                <div
                    className={`sticky top-0 left-0 z-40 w-full ${noHeaderActual ? 'hidden' : ''}`}
                    id="navigation"
                >
                    <Header />
                    {subHeaderActual && <SubHeader show={true} />}
                </div>

                <main className="relative min-h-screen w-full" id="modals-and-content">
                    {props.children}

                    <SearchModal />
                    <ShopNavigation />
                    <TopicsNavigation />
                    <IWIModal />
                    <UserContextModal />
                    <MoxieModal />
                    <FavouritesModal />
                    <ContactFormLegacy />
                    <IWIModalTransparent />
                </main>

                {footerActual && <Footer />}
            </AuthGuard>
        </>
    );
};

Scene.defaultProps = {
    subheader: true,
    footer: true,
    noHeader: false,
};

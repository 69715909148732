import React from 'react';

export interface IProgressDots {
    stages: number;
    currentStage: number;
}

export const ProgressDots: React.FC<IProgressDots> = (props) => {
    const { stages, currentStage } = props;

    return (
        <div className="relative flex h-max w-max flex-row gap-2">
            {Array.from(Array(stages).keys()).map((x) => (
                <div
                    key={`progress-stage-${x}`}
                    className={`h-6 w-6 rounded-full ${
                        x < currentStage ? 'bg-primary-light' : 'bg-[#E0E0E0]'
                    }`}
                ></div>
            ))}
        </div>
    );
};

export const languageListAvailable: string[] = [
    'en-GB',
    'de-DE',
    'de-AT',
    'de-CH',
    'de-LU',
    'fr-BE',
    'nl-BE',
    'cs-CZ',
    'et-EE',
    'es-ES',
    'hr-HR',
    'fr-FR',
    'fr-LU',
    'fr-CH',
    'it-IT',
    'it-CH',
    'lv-LV',
    'lt-LT',
    'hu-HU',
    'mt-MT',
    'nl-NL',
    'sr-ME',
    'nb-NO',
    'pl-PL',
    'pt-PT',
    'ro-RO',
    'sq-AL',
    'sk-SK',
    'sl-SI',
    'fi-FI',
    'sv-SE',
    'el-GR',
    'el-CY',
    'bg-BG',
    'sr-RS',
    'is-IS',
];

import React from 'react';
import { getBasePath } from '../../../helper/getBasePath';
import Image from 'next/legacy/image';
import Link from 'next/link';
import { useNavigationHandler } from '../../Header/hooks/useNavigationHandler';

export const LogoTypoSmall: React.FC = () => {
    const { resetNavigation } = useNavigationHandler();

    return (
        <>
            <figure className="relative -top-3 flex h-full max-w-[150px] items-start justify-start">
                <Link href="/home" onClick={() => resetNavigation()}>
                    <Image
                        className="relative h-full"
                        quality={100}
                        width={157}
                        height={57}
                        objectFit="contain"
                        src={`${getBasePath()}/icons/rmc-logo-typo.png`}
                        alt="logo RE/MAX Marketing Center"
                        aria-hidden={true}
                    />
                </Link>
            </figure>
        </>
    );
};

import React, { useMemo } from 'react';
import { ProfileHeadline } from '../../../ProfileHeadline/ProfileHeadline';
import { ProgressDots } from '../progressDots/ProgressDots';
import { useAllLocales } from '../../../../hooks/locale/useAllLocales';

export interface IStageHeader {
    stages: number;
    currentStage: number;
}

export const StageHeader: React.FC<IStageHeader> = (props) => {
    const { currentStage, stages } = props;

    const locales = useAllLocales();

    const headlineToUse = useMemo(() => {
        switch (currentStage) {
            case 1:
                return locales?.['LL_OnBoarding_Popup_1_Headline'] ?? 'Der erste eindruck zählt';
            case 2:
                return locales?.['LL_OnBoarding_Popup_2_Headline'] ?? 'Foto vom Profi';
            case 3:
                return (
                    locales?.['LL_OnBoarding_Popup_5a_Headline'] ??
                    'Profilbild mit freigestelltem Oberkörper'
                );
            case 4:
                return locales?.['LL_OnBoarding_Popup_6_Headline'] ?? 'Foto hochladen';
            case 5:
                return locales?.['LL_OnBoarding_Popup_7_Headline'] ?? 'E-Mail-adresse und Name';
            default:
                return null;
        }
    }, [currentStage, locales]);

    return (
        <div className="relative flex w-full flex-col gap-4 border-b-2">
            <ProfileHeadline
                headline={locales?.['LL_OnBoarding_Popup_General_Headline'] ?? 'Portrait-Service'}
                type="h1"
            />
            <div className="relative flex h-max w-full flex-row justify-between pb-4">
                <ProfileHeadline headline={`${currentStage}. ${headlineToUse ?? ''}`} type="h2" />
                <ProgressDots stages={stages} currentStage={currentStage} />
            </div>
        </div>
    );
};

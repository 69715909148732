import React from 'react';
import { withBasePath } from '../../../../../helper/getBasePath';
import { useAllLocales } from '../../../../../hooks/locale/useAllLocales';

export interface IStage2 {}

export const Stage2: React.FC<IStage2> = () => {
    const locales = useAllLocales();

    return (
        <div className="relative grid h-max w-full grid-cols-[3fr,3fr,3fr,3fr] gap-2">
            <div className="relative flex aspect-[264/240] w-full flex-col items-center justify-center overflow-hidden">
                <img
                    src={withBasePath('/images/iwi/03-remax-pbservice-illu-01.svg')}
                    alt="icon"
                    className="h-full w-full object-cover"
                />
            </div>
            <div className="relative flex flex-col justify-start gap-5 text-black">
                <p
                    dangerouslySetInnerHTML={{
                        __html: locales?.['LL_OnBoarding_Popup_3_Copy'] ?? '',
                    }}
                />
            </div>
            <div className="relative flex aspect-[264/240] w-full flex-col items-center justify-center overflow-hidden">
                <img
                    src={withBasePath('/images/iwi/04-remax-pbservice-illu-01.svg')}
                    alt="icon"
                    className="h-full w-full object-cover"
                />
            </div>
            <div className="relative flex flex-col justify-between text-black">
                <p
                    dangerouslySetInnerHTML={{
                        __html: locales?.['LL_OnBoarding_Popup_4_Copy'] ?? '',
                    }}
                />
            </div>
        </div>
    );
};

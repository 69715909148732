import { useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useApi } from '../../useApi';

interface IWIInput {
    firstName: string;
    lastName: string;
    email: string;
    DSVGO_Contract: string;
    fileName: string;
}

export const useIWISubmit = () => {
    const { postUpload } = useApi();

    return useMutation(async (inputLocal: { input: IWIInput; file: File }) => {
        const { uploadIWI } = await postUpload<{
            uploadIWI: { success: boolean; errorText?: string };
        }>(
            gql`
                mutation ($input: IWIUploadArgs!, $file: Upload!) {
                    uploadIWI(file: $file, input: $input) {
                        success
                        errorText
                    }
                }
            `,
            { input: inputLocal.input, file: null },
            { '0': ['variables.file'] },
            [inputLocal.file]
        );
        return uploadIWI;
    });
};

import React from 'react';
import ModalFullscreen from '../Modal/ModalFullscreen';
import { useRecoilState } from 'recoil';
import { shopSearchStateRecoil } from './recoil/shopSearch';
import { useProductSearch } from '../../hooks/useProductSearch';
import { nanoid } from 'nanoid';
import Image from 'next/legacy/image';
import { useAllLocales } from '../../hooks/locale/useAllLocales';

export const SearchModal: React.FC = () => {
    const [searchState, setSearchState] = useRecoilState(shopSearchStateRecoil);
    const searchedProducts = useProductSearch(searchState.needle ?? '');

    const locales = useAllLocales();

    return (
        <>
            <ModalFullscreen
                show={searchState.needle.length > 0}
                closeFunction={() => {
                    setSearchState({
                        ...searchState,
                        showSearch: false,
                        needle: '',
                        needleLocal: '',
                    });
                }}
            >
                {locales && (
                    <div className="relative mx-auto flex  w-full max-w-7xl flex-row flex-wrap gap-4 gap-y-8 overflow-x-auto overflow-y-hidden p-10">
                        {searchedProducts.length > 0 &&
                            searchedProducts.map((product) => {
                                return (
                                    <a
                                        href={`/shop/productoverview/${product.id}`}
                                        className="relative flex w-[259px] flex-col gap-2"
                                        key={nanoid()}
                                    >
                                        <div className="relative aspect-[1/1] h-[309px] w-full opacity-90 transition-all hover:scale-105 hover:opacity-100 ">
                                            <Image
                                                src={product.thumbnail}
                                                alt={product.translation ?? ''}
                                                layout="fill"
                                                objectFit="cover"
                                                quality={80}
                                                loading="lazy"
                                                placeholder="blur"
                                                // https://png-pixel.com/
                                                blurDataURL="/shop/images/blur/1x1-dcdcdc51.png"
                                            />
                                        </div>
                                        <span className="flex-wrap whitespace-pre-wrap text-sm text-white">
                                            {product.translation}
                                        </span>
                                    </a>
                                );
                            })}

                        {searchedProducts.length === 0 && (
                            <>
                                <section className="pt-30 relative flex flex w-full items-center justify-center pt-20">
                                    <span className="text-2xl text-white">
                                        {locales['LL_General_Header_NoResult1']}{' '}
                                        <span className="text-primary-light">
                                            {searchState.needle}
                                        </span>{' '}
                                        {locales['LL_General_Header_NoResult2']}
                                    </span>
                                </section>
                            </>
                        )}
                    </div>
                )}
            </ModalFullscreen>
        </>
    );
};

// https://regex101.com/r/0bH043/1
// https://regex101.com/library/0bH043?filterFlavors=javascript&orderBy=RELEVANCE&search=password
export const passwordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,24}$/;

/**
 * ###### Valid
 * ^@},hJu>[4Bo7TGX
 * _}24I:9t58Tu?m@e
 * |YlzEc|1
 * #m_4xF%t"Bu5jeb$
 *
 * #### Invalid
 * 12345678aA
 * ^@},^@},^@},
 * 12345678aA@
 * 12345678 aA@
 *  12345678aA@
 * AbCdEfGhIi
 * abcdefgh123
 * ABCDEFGH1234
 */

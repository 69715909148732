import React, { ChangeEvent, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useRecoilState } from 'recoil';
import { shopSearchStateRecoil } from './recoil/shopSearch';
import { useDebounce } from 'react-use';
import { useNavigationHandler } from '../Header/hooks/useNavigationHandler';

export const SearchBarModern: React.FC = () => {
    const [searchState, setSearchState] = useRecoilState(shopSearchStateRecoil);

    const { resetNavigation } = useNavigationHandler();

    const change = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            resetNavigation();
            setSearchState({ ...searchState, needleLocal: e.target.value ?? '' });
        },
        [resetNavigation, searchState, setSearchState]
    );

    // Smooth, Junge, Smooth
    useDebounce(
        () => {
            // Update with debounce to avoid API overload
            if (searchState.needle !== searchState.needleLocal) {
                setSearchState({ ...searchState, needle: searchState.needleLocal ?? '' });
            }
        },
        500,
        [searchState.needle, searchState.needleLocal]
    );

    return (
        <>
            <section className="relative hidden h-12 w-full md:flex">
                {/*Icon*/}
                <div className="absolute right-1 bottom-2 z-10">
                    <FontAwesomeIcon icon={faSearch} className="text-neutral-300" />
                </div>

                <input
                    value={searchState.needleLocal ?? ''}
                    className="placeholder: relative w-full rounded border-b-[1px] border-neutral-300 p-2 pb-0 text-sm placeholder:font-light placeholder:italic placeholder:text-neutral-300"
                    placeholder="Search..."
                    onChange={(e) => change(e)}
                />

                {searchState.needleLocal !== '' && (
                    <button
                        className="absolute top-4 right-6 z-20 flex aspect-[1/1] h-[calc(100%-20px)] items-center justify-center rounded-full hover:bg-neutral-200"
                        onClick={() => {
                            setSearchState({ ...searchState, needle: '', needleLocal: '' });
                        }}
                    >
                        <FontAwesomeIcon icon={faXmark} className="text-xs text-neutral-500" />
                    </button>
                )}
            </section>
        </>
    );
};

export default SearchBarModern;

import { useProductsAll } from './api/gql/products/useProductsAll';
import { useMemo } from 'react';
import { IProduct } from '../interfaces/product/product';
import Fuse from 'fuse.js';

export const useProductSearch = (input: string) => {
    const { data: products } = useProductsAll();

    return useMemo<IProduct[]>(() => {
        if (!products || !input) {
            return [];
        }

        // Filter products which you shouldn't see
        const productsFiltered = products.filter((product) => product.visible);

        let fuse: null | Fuse<IProduct>;

        // write me a if clause where asked if input:string is a 5-6 digit number
        if (/^\d{3,6}$/.test(input)) {
            // https://fusejs.io/api/options.html
            fuse = new Fuse(productsFiltered, {
                keys: ['fields.wawiArtikelnummer'],
                shouldSort: true,
                includeScore: true,
                threshold: 0.1,
                distance: 100,
                findAllMatches: false,
                minMatchCharLength: 3,
            });
        } else {
            // https://fusejs.io/api/options.html
            fuse = new Fuse(productsFiltered, {
                keys: ['name', 'translation'],
                shouldSort: true,
                includeScore: true,
                threshold: 0.3,
                distance: 100,
                findAllMatches: false,
                minMatchCharLength: 3,
            });
        }

        const fuzzed = fuse.search(input);

        const filteredProducts: IProduct[] = [];

        // Map to IProducts[]
        fuzzed.forEach((item) => {
            filteredProducts.push(item.item);
        });

        return filteredProducts;
    }, [products, input]);
};

import React, { PropsWithChildren, useMemo } from 'react';
import Link from 'next/link';

export interface IRmcLink extends PropsWithChildren {
    href: string;
    target?: string;
    fontWeight?: 'medium' | 'regular';
    fontSize?: 'text-sm' | 'text-base' | 'text-lg' | 'text-xs';
}

export const RmcLink: React.FC<IRmcLink> = (props) => {
    const { href, target, fontWeight, children, fontSize } = props;

    const weightStyles = useMemo(() => {
        return fontWeight === 'medium' ? 'font-medium' : '';
    }, [fontWeight]);

    return (
        <>
            <Link
                href={href}
                target={target ?? undefined}
                className={`relative inline-flex text-primary underline transition-all hover:text-primary-light ${weightStyles} ${
                    fontSize as string
                }`}
            >
                {children}
            </Link>
        </>
    );
};

RmcLink.defaultProps = {
    fontWeight: 'medium',
    fontSize: 'text-base',
};

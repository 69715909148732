import React, { PropsWithChildren } from 'react';

interface IButtonToolbar extends PropsWithChildren {
    start?: JSX.Element[];
    end?: JSX.Element[];
}

export const ButtonToolbar: React.FC<IButtonToolbar> = (props) => {
    const { start, end, children } = props;

    return (
        <>
            <section className="relative flex h-full w-full flex-row flex-nowrap gap-4 whitespace-nowrap">
                <div className="flex w-full flex-grow flex-row justify-start gap-2 md:w-max">
                    {start && <> {start?.map((item) => item)}</>}
                </div>
                {children && (
                    <div className="relative w-full flex-grow flex-row flex-nowrap whitespace-nowrap">
                        {children}
                    </div>
                )}
                <div className="flex w-full flex-shrink flex-row justify-end gap-1 md:w-max">
                    {end && <>{end?.map((item) => item)}</>}
                </div>
            </section>
        </>
    );
};

import React from 'react';
import ModalFullscreen from '../Modal/ModalFullscreen';
import { useRecoilState } from 'recoil';
import { userContextStateRecoil } from './recoil/userContextState';
import { UserContextLanguageSelector } from './UserContextLanguageSelector';
import { UserContextMenu } from './UserContextMenu';
import { useAllLocales } from '../../hooks/locale/useAllLocales';
import { useUser } from '../../hooks/api/gql/user/queries/useUser';

export const UserContextModal: React.FC = () => {
    const [userContextState, setUserContextState] = useRecoilState(userContextStateRecoil);
    const { data: userData } = useUser();

    const locales = useAllLocales();

    return (
        <>
            <ModalFullscreen
                show={userContextState.showModal}
                closeFunction={() => {
                    if (userContextState.view === 'default') {
                        setUserContextState({
                            ...userContextState,
                            showModal: false,
                        });
                    }
                    if (userContextState.view === 'languages') {
                        setUserContextState({
                            ...userContextState,
                            view: 'default',
                        });
                    }
                }}
            >
                {locales && userContextState.view === 'default' && (
                    <div className="relative mx-auto flex w-full max-w-7xl flex-col flex-wrap items-center gap-4 gap-y-8 overflow-x-auto overflow-y-hidden p-10">
                        <UserContextMenu />
                    </div>
                )}

                {locales && userContextState.view === 'languages' && (
                    <div className="relative mx-auto flex w-full max-w-5xl flex-col flex-wrap items-center gap-4 gap-y-8 p-10 pb-32">
                        <UserContextLanguageSelector
                            currentLanguage={userData?.language ?? 'en-GB'}
                        />
                    </div>
                )}
            </ModalFullscreen>
        </>
    );
};

import React, { useCallback, useRef, useState } from 'react';
import { withBasePath } from '../../../../../helper/getBasePath';
import { RmcLink } from '../../../../atoms/RmcLink/RmcLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faCloudUpload, faSync, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../../../../atoms/Buttons/Button';
import { useDropzone } from 'react-dropzone';
import Jimp from 'jimp';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import { iwiStateTransparentRecoil } from '../../../../IWI/recoil/iwiStateTransparent';
import { useAllLocales } from '../../../../../hooks/locale/useAllLocales';

export interface IStage4 {}

export const Stage4: React.FC<IStage4> = () => {
    const [iwiState, setIwiState] = useRecoilState(iwiStateTransparentRecoil);

    const locales = useAllLocales();

    const inputRef = useRef<HTMLInputElement | null>(null);

    const [isUploading, setIsUploading] = useState(false);

    const sanitizeFilename = useCallback((file: File): File => {
        const sanitizedFileName = file.name.replace(/[^a-zA-Z0-9.]/g, '');
        return new File([file], sanitizedFileName.toLowerCase(), { type: file.type });
    }, []);

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            setIsUploading(true);

            if (acceptedFiles[0]) {
                const file = sanitizeFilename(acceptedFiles[0]);

                void acceptedFiles[0].arrayBuffer().then((buffer) => {
                    // Note: maxMemoryUsageInMB is set in _app.tsx
                    Jimp.read(buffer as Buffer)
                        .then((image) => {
                            const imageLoaded = image;

                            if (
                                (file.type === 'image/jpeg' ||
                                    file.type === 'image/jpg' ||
                                    file.type === 'image/png') &&
                                imageLoaded.bitmap.height >= 1063 &&
                                imageLoaded.bitmap.width >= 827
                            ) {
                                setIwiState({ ...iwiState, image: file });
                            } else {
                                toast.error(
                                    locales?.['LL_IWI_Warning_MissingQuality'] ??
                                        'Your image does not meet the quality requirements (min. Resolution 827 x 1063 pixels).'
                                );
                                setIwiState({ ...iwiState, image: null });
                                if (inputRef.current) {
                                    inputRef.current.value = '';
                                }
                            }
                        })
                        .catch((e: Error) => {
                            // eslint-disable-next-line no-console
                            console.log(e, e.message);

                            if (e.message.toLowerCase().includes('maxmemoryusageinmb')) {
                                toast.error(
                                    locales?.['IWI_Uploader_Error_TooLarge'] ??
                                        'Image too large, please reduce the size'
                                );
                            }
                        });
                });
            }
        },
        [iwiState, locales, sanitizeFilename, setIwiState]
    );

    const { getRootProps, getInputProps, isDragActive, isDragAccept } = useDropzone({
        onDrop,
        multiple: false,
        accept: {
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/jpg': ['.jpg', '.jpeg'],
            'image/png': ['.png'],
        },
    });

    return (
        <div className="relative grid h-max w-full grid-cols-[286px,504px,auto] gap-4">
            {/* Camera Icon */}
            <div className="relative flex aspect-[286/247] w-full flex-col items-center justify-center overflow-hidden">
                <img
                    src={withBasePath('/images/iwi/UploadIcon.png')}
                    alt="icon"
                    className="h-full w-full object-cover"
                />
            </div>
            {isUploading && (
                <div className="relative flex h-full w-full flex-row items-center justify-center bg-primary-light/10">
                    <FontAwesomeIcon
                        icon={faSync}
                        className="h-16 animate-spin text-primary-light"
                    />
                </div>
            )}
            {/* Image Upload and Requirements Text */}
            {!isUploading && (
                <div className="relative flex flex-col gap-4">
                    <input
                        {...getInputProps()}
                        className="hidden"
                        accept="image/jpeg, image/png"
                        ref={inputRef}
                    />
                    {/* Image Upload*/}
                    <div
                        {...getRootProps()}
                        className={`relative flex h-max w-full flex-col items-center justify-center gap-9 py-7 ${
                            isDragActive && isDragAccept
                                ? 'animate-pulse border-solid border-green-600 bg-green-500/10'
                                : 'bg-primary-light/10'
                        } ${
                            isDragActive && !isDragAccept
                                ? 'animate-pulse border-solid border-red-600 bg-red-500/10'
                                : 'bg-primary-light/10'
                        }}`}
                        onClick={(event) => {
                            // Prevent click, to up file modal. This is handled in child elements
                            event.preventDefault();
                        }}
                    >
                        {!isDragActive && (
                            <>
                                <FontAwesomeIcon
                                    icon={faCloudUpload}
                                    className="text-5xl text-primary-light"
                                />
                                <div className="relative w-max">
                                    <Button
                                        height="h-8"
                                        color="primary"
                                        addtionalCss="px-6"
                                        onClick={() => {
                                            inputRef.current?.click();
                                        }}
                                    >
                                        {locales?.['RMC_IWI_Image_Select_Button'] ??
                                            'Bilddatei auswählen'}
                                    </Button>
                                </div>
                            </>
                        )}
                        {isDragActive && isDragAccept && (
                            <div className={'flex h-[116px] items-center'}>
                                <FontAwesomeIcon
                                    icon={faChevronDown}
                                    className="text-4xl text-green-600"
                                />
                            </div>
                        )}{' '}
                        {isDragActive && !isDragAccept && (
                            <div className={'flex h-[116px] items-center'}>
                                <FontAwesomeIcon icon={faTimes} className="text-4xl text-red-600" />
                            </div>
                        )}
                    </div>

                    {/* Requirements Text */}
                    <div className="relative flex flex-col justify-start">
                        <p
                            dangerouslySetInnerHTML={{
                                __html: locales?.['LL_IWI_Requirement_File'] ?? '',
                            }}
                        />
                        <p
                            dangerouslySetInnerHTML={{
                                __html: locales?.['LL_IWI_Requirement_Resolution'] ?? '',
                            }}
                        />
                    </div>
                </div>
            )}
            {/* Text with Link */}
            <div className="relative flex flex-col justify-start gap-7 text-black">
                <p
                    dangerouslySetInnerHTML={{
                        __html: locales?.['LL_OnBoarding_Popup_6_Copy'] ?? '',
                    }}
                />

                <RmcLink
                    href={
                        'https://www.marketingcenter.remax.eu/Custom/Downloads/HTML_Link_PDF_02_PortraitService/REMAX_Portrait_Briefing_de-DE.pdf'
                    }
                    target="_blank"
                    fontSize="text-base"
                    fontWeight="medium"
                >
                    {locales?.['LL_OnBoarding_Popup_Download_PortraitBriefing'] ??
                        'Porträtfoto-Anleitung herunterladen'}
                </RmcLink>
            </div>
        </div>
    );
};

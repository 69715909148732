import { atom } from 'recoil';
import { nanoid } from 'nanoid';

export interface IUbootState {
    ref: HTMLIFrameElement | null;
}

export const ubootStateRecoil = atom<IUbootState>({
    key: `uboot-state/${nanoid()}`,
    default: {
        ref: null,
    },
});

import React from 'react';
import { UserContextButton } from './UserContextButton';
import {
    faAddressCard,
    faBookOpenReader,
    faBookReader,
    faFloppyDisk,
    faGear,
    faImages,
    faKey,
    faPowerOff,
    faQuestionCircle,
    faScrewdriverWrench,
    faTruck,
} from '@fortawesome/free-solid-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { nanoid } from 'nanoid';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { userContextStateRecoil } from './recoil/userContextState';
import { iwiStateRecoil } from '../IWI/recoil/iwiState';
import { FlagSymbol } from '../atoms/FlagSymbol/FlagSymbol';
import { moxieStateRecoil } from '../legacy/MoxieManager/recoil/moxieState';
import { useNavigationHandler } from '../Header/hooks/useNavigationHandler';
import { getBasePath, withHostPathAbsolute, withUbootPath } from '../../helper/getBasePath';
import { useAllLocales } from '../../hooks/locale/useAllLocales';
import { useUser } from '../../hooks/api/gql/user/queries/useUser';
import { iwiStateTransparentRecoil } from '../IWI/recoil/iwiStateTransparent';
import { contactFormStateRecoil } from '../contactFormLegacy/state/contactFormState';

export const UserContextMenu: React.FC = () => {
    const [userContextState, setUserContextState] = useRecoilState(userContextStateRecoil);
    const setIwiState = useSetRecoilState(iwiStateRecoil);
    const setIwiTransparentState = useSetRecoilState(iwiStateTransparentRecoil);
    const setMoxieState = useSetRecoilState(moxieStateRecoil);
    const setContactFormState = useSetRecoilState(contactFormStateRecoil);

    const { data: userData } = useUser();
    const { resetNavigation } = useNavigationHandler();

    const locales = useAllLocales();

    return (
        <>
            <section className="relative -left-4 flex w-full max-w-3xl flex-row items-center gap-4">
                <button
                    onClick={() => {
                        setUserContextState({ ...userContextState, view: 'languages' });
                    }}
                    className="w-12"
                >
                    {/*<FlagSymbol code={userData?.language ?? 'en-GB'} />*/}
                </button>
                {locales && locales['LL_UserMenu_Headline'] && (
                    <h1 className="text-4xl text-white">{locales['LL_UserMenu_Headline']}</h1>
                )}
            </section>

            {locales && (
                <section className="relative grid w-full max-w-3xl grid-cols-[6fr,6fr] gap-10">
                    {/*Spalte 1*/}
                    <div className="flex flex-col gap-0 pl-10">
                        <UserContextButton
                            icon={faGear}
                            color="primary-light"
                            label={locales['LL_General_BenutzerProfil'] ?? 'Profile'}
                            href={withHostPathAbsolute('shop/profile')}
                            onClick={() => {
                                setUserContextState({
                                    ...userContextState,
                                    showModal: false,
                                });
                            }}
                        />
                        <UserContextButton
                            icon={faAddressCard}
                            color="primary-light"
                            label={
                                locales['LL_General_DigitaleVisitenkarte'] ??
                                'Digitale Visitenkarte'
                            }
                            href={withHostPathAbsolute('shop/card')}
                            onClick={() => {
                                setUserContextState({
                                    ...userContextState,
                                    showModal: false,
                                });
                            }}
                        />
                        <UserContextButton
                            iconJSX={
                                <div className="w-8">
                                    <FlagSymbol code={userData?.language ?? 'en-GB'} />
                                </div>
                            }
                            color="primary-light"
                            label={
                                locales['LL_General_Header_ChooseLanguage'] ??
                                'Change your language'
                            }
                            onClick={() => {
                                setUserContextState({ ...userContextState, view: 'languages' });
                            }}
                        />
                        <UserContextButton
                            // icon={faCamera}
                            iconJSX={
                                <img
                                    src={`${getBasePath()}/icons/userContextMenu/Portraitfoto-Icon.svg`}
                                    className="w-10"
                                    alt="icon portrait"
                                />
                            }
                            color="primary-light"
                            label={locales['LL_Profil_Headline_PortraitPicture'] ?? 'Bilderservice'}
                            onClick={() => {
                                setUserContextState({
                                    ...userContextState,
                                    showModal: false,
                                });

                                setIwiState((currVal) => {
                                    return { ...currVal, show: true };
                                });
                            }}
                        />
                        {/* Transparent Portrait Service */}
                        <UserContextButton
                            iconJSX={
                                <img
                                    src={`${getBasePath()}/icons/userContextMenu/Transparent-Profilfoto-Icon.svg`}
                                    className="w-10"
                                    alt="icon"
                                />
                            }
                            color="primary-light"
                            label={
                                locales['LL_OnBoarding_Popup_Bodyphoto_Headline'] ??
                                'Transparent Portrait Service'
                            }
                            onClick={() => {
                                setUserContextState({
                                    ...userContextState,
                                    showModal: false,
                                });

                                setIwiTransparentState((currVal) => {
                                    return { ...currVal, show: true };
                                });
                            }}
                        />
                        <div className="block h-8" />
                        <UserContextButton
                            icon={faTruck}
                            label={locales['LL_General_MeineBestellungen'] ?? 'My Orders'}
                            href={withHostPathAbsolute('shop/orderhistory')}
                            onClick={() => {
                                setUserContextState({
                                    ...userContextState,
                                    showModal: false,
                                });
                            }}
                        />
                        <UserContextButton icon={faBookReader} label={'E-Books'} href={''} />
                        <UserContextButton
                            icon={faBookOpenReader}
                            label={locales['LL_Flipbooks'] ?? 'E-Books'}
                            href={withUbootPath('content.php?categoryCode=Flipbook')}
                            onClick={() => {
                                setUserContextState({
                                    ...userContextState,
                                    showModal: false,
                                });
                            }}
                        />
                        <UserContextButton
                            icon={faFloppyDisk}
                            label={
                                locales['LL_General_Header_MySavedProducts'] ?? 'My saved products'
                            }
                            href={withHostPathAbsolute('shop/cart?section=forLater')}
                            onClick={() => {
                                setUserContextState({
                                    ...userContextState,
                                    showModal: false,
                                });
                            }}
                        />
                        <div className="block h-10" />
                        {/* TODO direkt relative geht nicht, muss gemacht werden. */}
                        {userData &&
                            userData.groups &&
                            (userData.groups.includes('BitsLib_Admin') ||
                                userData.groups.includes('EXF_RMC_RegionalUserManagement')) && (
                                <UserContextButton
                                    icon={faScrewdriverWrench}
                                    label={'User Management'}
                                    href={withUbootPath(
                                        'ExtendedFrontend.php?Module=UserManagementRegional&Function=Default'
                                    )}
                                    onClick={() => {
                                        setUserContextState({
                                            ...userContextState,
                                            showModal: false,
                                        });
                                    }}
                                    key={nanoid()}
                                />
                            )}
                        <div className="block h-10" />
                        <UserContextButton
                            icon={faPowerOff}
                            label={locales['LL_General_Header_Logout'] ?? 'Logout'}
                            href={'/logout'}
                        />
                    </div>

                    {/*Spalte 2*/}
                    <div className="flex flex-col gap-0 pl-10">
                        <UserContextButton
                            iconJSX={
                                <img
                                    src={`${getBasePath()}/icons/userContextMenu/Webinar-Icon.svg`}
                                    className="w-10"
                                    alt="icon"
                                />
                            }
                            color="primary-light"
                            label={locales['LL_Webinare'] ?? 'Webinars'}
                            href={`/content/webinars`}
                            onClick={() => {
                                setUserContextState({
                                    ...userContextState,
                                    showModal: false,
                                });
                            }}
                        />

                        <UserContextButton
                            icon={faImages}
                            color="primary-light"
                            label={locales['LL_General_MyMediaManager'] ?? 'Media Manager'}
                            onClick={() => {
                                resetNavigation();
                                setMoxieState({ show: true, type: 'Standard' });
                            }}
                        />
                        <UserContextButton
                            icon={faQuestionCircle}
                            label={locales['LL_FAQ'] ?? 'FAQ'}
                            href={withHostPathAbsolute('content.php?categoryCode=FAQ')}
                        />
                        <div className="block h-8" />
                        <UserContextButton
                            icon={faYoutube}
                            label={locales['LL_Tutorials'] ?? 'Tutorials'}
                            href={withHostPathAbsolute('content.php?categoryCode=VideoTutorial')}
                            iconColor="red"
                        />
                        {/*<UserContextButton*/}
                        {/*    icon={faFolderTree}*/}
                        {/*    label={'Document Center'}*/}
                        {/*    href={withHostPathAbsolute('DocumentCenter.php')}*/}
                        {/*/>*/}
                        <UserContextButton
                            icon={faKey}
                            label={
                                locales['LL_NonUpload_Profil_Passwort_Aendern_Link'] ??
                                'Change Password'
                            }
                            href={'/profile'}
                            key={nanoid()}
                            onClick={() => {
                                setUserContextState({
                                    ...userContextState,
                                    showModal: false,
                                });
                            }}
                        />
                        <div className="block h-8" />

                        {/* Kontaktformular */}
                        <UserContextButton
                            iconJSX={
                                <img
                                    src={`${getBasePath()}/icons/userContextMenu/Kontaktformular-Icon.svg`}
                                    className="w-10"
                                    alt="icon"
                                />
                            }
                            color="primary-light"
                            label={
                                locales['LL_Kontaktformular_PopUpHeader_Kontaktformular'] ??
                                'Contact'
                            }
                            onClick={() => {
                                setUserContextState({
                                    ...userContextState,
                                    showModal: false,
                                });

                                setContactFormState((currVal) => {
                                    return { ...currVal, show: true };
                                });
                            }}
                        />
                    </div>
                </section>
            )}
        </>
    );
};

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { iwiStateRecoil } from './recoil/iwiState';
import 'swiper/css';
import { IWIContentBox } from './IWIContentBox';
import { getBasePath, withHostPath } from '../../helper/getBasePath';
import { ButtonToolbar } from '../Toolbars/ButtonToolbar';
import { nanoid } from 'nanoid';
import { Button } from '../atoms/Buttons/Button';
import {
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faCloudUpload,
    faEnvelope,
    faTimes,
    faUserTie,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDropzone } from 'react-dropzone';
import { Textfield } from '../forms/Textfield';
import { toast } from 'react-toastify';
import Jimp from 'jimp';
import { ModalFullscreenNeutral } from '../atoms/Modal/ModalFullscreenNeutral';
import { useIWISubmit } from '../../hooks/api/gql/iwi/useIWISubmit';
import { useAllLocales } from '../../hooks/locale/useAllLocales';
import { useUser } from '../../hooks/api/gql/user/queries/useUser';

export const IWIModal: React.FC = () => {
    const { data: userData } = useUser();
    const [iwiState, setIwiState] = useRecoilState(iwiStateRecoil);

    const locales = useAllLocales();

    const [currentSlider, setCurrentSlider] = useState(1);

    const { mutate: uploadIwi, isLoading } = useIWISubmit();

    const inputRef = useRef<HTMLInputElement | null>(null);
    const agbRef = useRef<HTMLDivElement | null>(null);

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            if (acceptedFiles[0]) {
                const file = acceptedFiles[0];

                void acceptedFiles[0].arrayBuffer().then((buffer) => {
                    // Note: maxMemoryUsageInMB is set in _app.tsx
                    Jimp.read(buffer as Buffer)
                        .then((image) => {
                            const imageLoaded = image;

                            if (
                                (file.type === 'image/jpeg' || file.type === 'image/jpg') &&
                                imageLoaded.bitmap.height >= 1063 &&
                                imageLoaded.bitmap.width >= 827
                            ) {
                                setIwiState({ ...iwiState, image: file });
                                setCurrentSlider(5);
                            } else {
                                toast.error(
                                    locales?.['LL_IWI_Warning_MissingQuality'] ??
                                        'Your image does not meet the quality requirements (min. Resolution 827 x 1063 pixels).'
                                );
                                setIwiState({ ...iwiState, image: null });
                            }
                        })
                        .catch((e: Error) => {
                            // eslint-disable-next-line no-console
                            console.log(e);

                            if (e.message.toLowerCase().includes('maxmemoryusageinmb')) {
                                toast.error(
                                    locales?.['IWI_Uploader_Error_TooLarge'] ??
                                        'Image too large, please reduce the size'
                                );
                            }
                        });
                });
            }
        },
        [iwiState, locales, setIwiState]
    );

    const { getRootProps, getInputProps, isDragActive, isDragAccept } = useDropzone({
        onDrop,
        multiple: false,
        accept: {
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/jpg': ['.jpg', '.jpeg'],
        },
    });

    const href = useMemo((): string => {
        if (locales) {
            return withHostPath(
                `/Custom/Downloads/HTML_Link_PDF_02_PortraitService/${
                    locales['LL_Profil_PortraitPictureService_Instruction_PDF_Link_File'] as string
                }`
            );
        }
        return '';
    }, [locales]);

    const swipeNext = useCallback(() => {
        setCurrentSlider(currentSlider + 1);
    }, [currentSlider]);

    const swipePrev = useCallback(() => {
        setCurrentSlider(currentSlider - 1);
    }, [currentSlider]);

    const submit = useCallback(() => {
        if (iwiState.image) {
            uploadIwi(
                {
                    input: {
                        firstName: iwiState.firstname,
                        lastName: iwiState.lastname,
                        email: iwiState.email,
                        fileName: iwiState.image?.name ?? '',
                        DSVGO_Contract: agbRef.current?.innerText ?? '',
                    },
                    file: iwiState.image,
                },
                {
                    onSuccess: () => {
                        toast.success(locales?.['LL_IWI_Upload_Success'] ?? 'Upload successful', {
                            toastId: 'iwi-success',
                        });

                        // Reset State
                        setIwiState({
                            show: false,
                            image: null,
                            lastname: '',
                            firstname: '',
                            email: '',
                        });

                        // Reset Slider
                        setCurrentSlider(1);

                        // Clear value
                        const inp = inputRef.current as HTMLInputElement;
                        if (inp) {
                            inp.value = '';
                        }
                    },
                    onError: (e) => {
                        // eslint-disable-next-line no-console
                        console.log('E', e);

                        // Default Fehler
                        toast.error(locales?.['LL_IWI_Upload_Fail_Error'] ?? 'Upload failed.', {
                            toastId: 'iwi-failed',
                        });
                    },
                }
            );
        } else {
            toast.error('Es wurde keine Datei angegeben', { toastId: 'iwi-failed' });
        }
    }, [
        iwiState.email,
        iwiState.firstname,
        iwiState.image,
        iwiState.lastname,
        locales,
        setIwiState,
        uploadIwi,
    ]);

    useEffect(() => {
        if (userData) {
            setIwiState((current) => {
                return {
                    ...current,
                    firstname: userData.fields['UserProfileFirstName'] ?? '',
                    lastname: userData.fields['UserProfileLastName'] ?? '',
                    email: userData.email ?? '',
                };
            });
        }
    }, [setIwiState, userData]);

    return (
        <>
            <ModalFullscreenNeutral
                show={iwiState.show}
                closeable={true}
                afterClose={() => setIwiState({ ...iwiState, show: false })}
            >
                {locales && (
                    <section className="relative flex h-full w-[1024px]  flex-col gap-2">
                        <h1 className="text-2xl text-neutral-600">
                            {locales['LL_OnBoarding_Popup_General_Headline'] ?? ''}
                        </h1>

                        {currentSlider === 1 && (
                            <div>
                                <IWIContentBox
                                    step={currentSlider}
                                    title={locales['LL_OnBoarding_Popup_1_Headline'] ?? ''}
                                >
                                    {/* Slide #1 */}
                                    <div className="relative flex w-full flex-row gap-2">
                                        <div className="flex aspect-[1/1] h-56 w-[300px] flex-row items-end justify-end ">
                                            {/* eslint-disable-next-line @next/next/no-img-element */}
                                            <img
                                                className="relative left-11 "
                                                src={`${getBasePath()}/images/iwi/01-remax-pbservice-illu-01.svg`}
                                                alt="image"
                                            />
                                        </div>
                                        <div
                                            className="w-40 pt-10 text-neutral-500"
                                            dangerouslySetInnerHTML={{
                                                __html: locales[
                                                    'LL_OnBoarding_Popup_1_Copy'
                                                ] as string,
                                            }}
                                        ></div>
                                        <div className="flex aspect-[1/1] h-56 w-[300px] flex-row items-end justify-end ">
                                            {/* eslint-disable-next-line @next/next/no-img-element */}
                                            <img
                                                className="relative left-11 "
                                                src={`${getBasePath()}/images/iwi/02-remax-pbservice-illu-01.svg`}
                                                alt="image"
                                            />
                                        </div>
                                        <div className="z-10 w-60 bg-white px-4 pt-10 text-neutral-500">
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: locales[
                                                        'LL_OnBoarding_Popup_2_Copy'
                                                    ] as string,
                                                }}
                                            />
                                            <a
                                                className="mt-4 inline-block font-medium text-primary underline"
                                                href={href}
                                                target="_iwi"
                                                dangerouslySetInnerHTML={{
                                                    __html: locales[
                                                        'LL_OnBoarding_Popup_Download_PortraitBriefing'
                                                    ] as string,
                                                }}
                                            />
                                        </div>
                                    </div>
                                </IWIContentBox>
                            </div>
                        )}

                        {currentSlider === 2 && (
                            <div>
                                <IWIContentBox
                                    step={currentSlider}
                                    title={locales['LL_OnBoarding_Popup_2_Headline'] ?? ''}
                                >
                                    {/* Slide #2 */}
                                    <div className="relative flex w-full flex-row gap-2">
                                        <div className="flex aspect-[1/1] h-56 w-[300px] flex-row items-end justify-end ">
                                            {/* eslint-disable-next-line @next/next/no-img-element */}
                                            <img
                                                className="relative left-11 "
                                                src={`${getBasePath()}/images/iwi/03-remax-pbservice-illu-01.svg`}
                                                alt="image"
                                            />
                                        </div>
                                        <div
                                            className="w-40 pt-10 text-neutral-500"
                                            dangerouslySetInnerHTML={{
                                                __html: locales[
                                                    'LL_OnBoarding_Popup_3_Copy'
                                                ] as string,
                                            }}
                                        />
                                        <div className="flex aspect-[1/1] h-56 w-[300px] flex-row items-end justify-end ">
                                            {/* eslint-disable-next-line @next/next/no-img-element */}
                                            <img
                                                className="relative left-11 "
                                                src={`${getBasePath()}/images/iwi/04-remax-pbservice-illu-01.svg`}
                                                alt="image"
                                            />
                                        </div>
                                        <div className="z-10 w-60 bg-white px-4 pt-10 text-neutral-500">
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: locales[
                                                        'LL_OnBoarding_Popup_4_Copy'
                                                    ] as string,
                                                }}
                                            />
                                        </div>
                                    </div>
                                </IWIContentBox>
                            </div>
                        )}

                        {currentSlider === 3 && (
                            <div>
                                <IWIContentBox
                                    step={currentSlider}
                                    title={locales['LL_OnBoarding_Popup_5a_Headline'] ?? ''}
                                >
                                    {/* Slide #2 */}
                                    <div className="relative flex w-full flex-row gap-2">
                                        <div className="flex flex-row items-end justify-end ">
                                            {/* eslint-disable-next-line @next/next/no-img-element */}
                                            <img
                                                className="relative h-44 border-2 border-neutral-400"
                                                src={`${getBasePath()}/images/iwi/RMC-IWI-Portrait-From-BG.jpg`}
                                                alt="image"
                                            />
                                        </div>
                                        <div className="flex h-full items-center justify-center">
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                className="relative  text-xl text-neutral-500"
                                            />
                                        </div>
                                        <div className="flex flex-row items-end justify-end ">
                                            {/* eslint-disable-next-line @next/next/no-img-element */}
                                            <img
                                                className="relative h-44 border-2 border-neutral-400"
                                                src={`${getBasePath()}/images/iwi/RMC-IWI-Portrait-From.jpg`}
                                                alt="image"
                                            />
                                        </div>
                                        <div className="flex h-full items-center justify-center">
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                className="relative  text-xl text-neutral-500"
                                            />
                                        </div>
                                        <div className="flex flex-row items-end justify-end ">
                                            {/* eslint-disable-next-line @next/next/no-img-element */}
                                            <img
                                                className="relative h-44 border-2 border-neutral-400"
                                                src={`${getBasePath()}/images/iwi/RMC-IWI-Portrait-To.jpg`}
                                                alt="image"
                                            />
                                        </div>
                                        <div
                                            className="relative ml-8 flex w-56 flex-col  pt-10 text-neutral-500"
                                            dangerouslySetInnerHTML={{
                                                __html: locales[
                                                    'LL_OnBoarding_Popup_5a_Copy'
                                                ] as string,
                                            }}
                                        />
                                    </div>
                                </IWIContentBox>
                            </div>
                        )}

                        {currentSlider === 4 && (
                            <div>
                                <IWIContentBox
                                    step={currentSlider}
                                    title={locales['LL_OnBoarding_Popup_6_Headline'] ?? ''}
                                >
                                    {/* Slide #2 */}
                                    <div className="relative flex w-full flex-row gap-6">
                                        <div className="flex aspect-[1/1] h-56 flex-row items-end justify-end ">
                                            {/* eslint-disable-next-line @next/next/no-img-element */}
                                            <img
                                                className="relative"
                                                src={`${getBasePath()}/images/iwi/UploadIcon.png`}
                                                alt="image"
                                            />
                                        </div>

                                        {/* Drag & Drop Area */}
                                        <div className="relative h-full w-full w-96 ">
                                            <input
                                                {...getInputProps()}
                                                className="hidden"
                                                accept="image/jpeg"
                                                ref={inputRef}
                                            />
                                            <div
                                                {...getRootProps()}
                                                className={`relative flex h-full w-full flex-col items-center justify-center gap-8 border-2 border-dotted border-primary-light bg-blue-500/10 ${
                                                    isDragActive && isDragAccept
                                                        ? 'animate-pulse border-solid border-green-600 bg-green-500/10'
                                                        : ''
                                                } ${
                                                    isDragActive && !isDragAccept
                                                        ? 'animate-pulse border-solid border-red-600 bg-red-500/10'
                                                        : ''
                                                }}`}
                                                onClick={(event) => {
                                                    // Prevent click, to up file modal. This is handled in child elements
                                                    event.preventDefault();
                                                }}
                                            >
                                                {!isDragActive && (
                                                    <>
                                                        <FontAwesomeIcon
                                                            icon={faCloudUpload}
                                                            className="text-4xl text-primary-light"
                                                        />
                                                        <Button
                                                            color="primary-light"
                                                            onClick={() => {
                                                                inputRef.current?.click();
                                                            }}
                                                        >
                                                            {locales['RMC_IWI_Image_Select_Button']}
                                                        </Button>
                                                    </>
                                                )}
                                                {isDragActive && isDragAccept && (
                                                    <>
                                                        <FontAwesomeIcon
                                                            icon={faChevronDown}
                                                            className="text-4xl text-green-600"
                                                        />
                                                    </>
                                                )}{' '}
                                                {isDragActive && !isDragAccept && (
                                                    <>
                                                        <FontAwesomeIcon
                                                            icon={faTimes}
                                                            className="text-4xl text-red-600"
                                                        />
                                                    </>
                                                )}
                                            </div>
                                            <div className="relative mt-4 flex flex-col gap-1 text-neutral-500">
                                                <span>{locales['LL_IWI_Requirement_File']}</span>
                                                <span>
                                                    {locales['LL_IWI_Requirement_Resolution']}
                                                </span>
                                            </div>
                                        </div>

                                        <div className="relative flex flex-col">
                                            <div
                                                className="w-40 pt-10 text-neutral-500"
                                                dangerouslySetInnerHTML={{
                                                    __html: locales[
                                                        'LL_OnBoarding_Popup_6_Copy'
                                                    ] as string,
                                                }}
                                            />

                                            <a
                                                className="mt-4 inline-block font-medium text-primary underline"
                                                href={href}
                                                target="_iwi"
                                                dangerouslySetInnerHTML={{
                                                    __html: locales[
                                                        'LL_OnBoarding_Popup_Download_PortraitBriefing'
                                                    ] as string,
                                                }}
                                            />
                                        </div>
                                    </div>
                                </IWIContentBox>
                            </div>
                        )}

                        {currentSlider === 5 && (
                            <div>
                                <IWIContentBox
                                    step={currentSlider}
                                    title={locales['LL_OnBoarding_Popup_8_Headline'] ?? ''}
                                >
                                    {/* Slide #2 */}
                                    <div className="relative mt-4 flex w-full flex-row gap-6">
                                        <div className="flex aspect-[1/1] h-56 flex-row items-end justify-end ">
                                            {/* eslint-disable-next-line @next/next/no-img-element */}
                                            <img
                                                className="relative"
                                                src={`${getBasePath()}/images/iwi/FormIcon.png`}
                                                alt="image"
                                            />
                                        </div>

                                        {/* Form Area */}
                                        <div className="relative flex h-full w-[800px] flex-col gap-4">
                                            <Textfield
                                                value={iwiState.firstname}
                                                label={locales['LL_Profil_FirstName'] ?? ''}
                                                autocomplete="firstname"
                                                icon={faUserTie}
                                                onChange={(value) => {
                                                    setIwiState({ ...iwiState, firstname: value });
                                                }}
                                            />
                                            <Textfield
                                                value={iwiState.lastname}
                                                label={locales['LL_Profil_LastName'] ?? ''}
                                                autocomplete="firstname"
                                                icon={faUserTie}
                                                onChange={(value) => {
                                                    setIwiState({ ...iwiState, lastname: value });
                                                }}
                                            />
                                            <Textfield
                                                value={iwiState.email}
                                                label={locales['LL_Profil_EmailAddress'] ?? ''}
                                                autocomplete="firstname"
                                                icon={faEnvelope}
                                                onChange={(value) => {
                                                    setIwiState({ ...iwiState, email: value });
                                                }}
                                            />
                                        </div>

                                        <div className="relative flex flex-col items-start justify-start gap-4 pt-4">
                                            <div
                                                className="relative flex text-neutral-500"
                                                dangerouslySetInnerHTML={{
                                                    __html: locales[
                                                        'LL_OnBoarding_Popup_7a_Copy'
                                                    ] as string,
                                                }}
                                            />
                                            <div
                                                className="text-neutral-500"
                                                dangerouslySetInnerHTML={{
                                                    __html: locales[
                                                        'LL_OnBoarding_Popup_7b_Copy'
                                                    ] as string,
                                                }}
                                            />
                                        </div>
                                    </div>
                                </IWIContentBox>

                                {/*Auftragsvereinbarungserklärung*/}
                                <div className="relative mt-10 flex w-full flex-col" ref={agbRef}>
                                    <h3
                                        className="font-medium text-primary"
                                        dangerouslySetInnerHTML={{
                                            __html: locales[
                                                'LL_OnBoarding_Popup_8_Headline'
                                            ] as string,
                                        }}
                                    />

                                    {/* Large Text*/}
                                    <div className="mt-4 text-xs text-neutral-400">
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: locales['RMC_IWI_AV_Copy_1'] as string,
                                            }}
                                        />
                                        <span className="text-primary">
                                            {iwiState.firstname}, {iwiState.lastname}
                                        </span>{' '}
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: locales['RMC_IWI_AV_Copy_2'] as string,
                                            }}
                                        />{' '}
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: locales['RMC_IWI_AV_Copy_3'] as string,
                                            }}
                                        />{' '}
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: locales['RMC_IWI_AV_Copy_4'] as string,
                                            }}
                                        />{' '}
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: locales['RMC_IWI_AV_Copy_5'] as string,
                                            }}
                                        />{' '}
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: locales['RMC_IWI_AV_Copy_6'] as string,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        <nav
                            className="relative z-50 mt-4 flex h-10 w-full flex-row"
                            slot="container-end"
                        >
                            <ButtonToolbar
                                start={[
                                    <div key={nanoid()}>
                                        {currentSlider > 1 && (
                                            <Button
                                                onClick={() => swipePrev()}
                                                color="default"
                                                iconStart={faChevronLeft}
                                            >
                                                Previous
                                            </Button>
                                        )}
                                    </div>,
                                ]}
                                end={[
                                    <div key={nanoid()}>
                                        {(currentSlider < 4 ||
                                            (currentSlider === 4 && iwiState.image !== null)) && (
                                            <Button
                                                onClick={() => swipeNext()}
                                                color="primary"
                                                iconEnd={faChevronRight}
                                            >
                                                Next
                                            </Button>
                                        )}
                                        {!isLoading && currentSlider >= 5 && (
                                            <Button
                                                onClick={() => submit()}
                                                color="primary"
                                                iconEnd={faCloudUpload}
                                            >
                                                Submit
                                            </Button>
                                        )}
                                        {isLoading && currentSlider >= 5 && (
                                            <Button
                                                onClick={() => {}}
                                                color="primary"
                                                iconEnd={faCloudUpload}
                                            >
                                                Submitting
                                            </Button>
                                        )}
                                    </div>,
                                ]}
                            ></ButtonToolbar>
                        </nav>
                    </section>
                )}
            </ModalFullscreenNeutral>
        </>
    );
};

import { useApi } from '../../../useApi';
import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';

export const useShoppingCartCount = () => {
    const { postGql } = useApi();

    return useQuery(
        ['shoppingCart', 'count'],
        async () => {
            const { shoppingCartItemCount } = await postGql<{
                shoppingCartItemCount: { inCart: number; inProgress: number; onHold: number };
            }>(
                gql`
                    query {
                        shoppingCartItemCount {
                            inCart
                            inProgress
                            onHold
                        }
                    }
                `,
                undefined,
                'shoppingCartItemCount'
            );
            return shoppingCartItemCount;
        },
        { staleTime: 60000 }
    );
};

import React from 'react';
import { LogoTypoSmall } from '../atoms/Logos/LogoTypoSmall';
import { useRecoilState } from 'recoil';
import { navigationShopAtom } from './recoil/shopNavigation';
import { LogoBalloon } from '../atoms/Logos/LogoBalloon';
import SearchBarModern from '../SearchBar/SearchBarModern';
import { UserAvatar } from '../UserContextMenu/UserAvatar';
import { useNavigationHandler } from './hooks/useNavigationHandler';
import { ShoppingCartButton } from '../ShoppingCart/ShoppingCartButton';
import { FavouriteButton } from '../Favourites/FavouriteButton';
import { useAllLocales } from '../../hooks/locale/useAllLocales';

export const Header: React.FC = () => {
    const [shopNavigationState, setShopNavigationState] = useRecoilState(navigationShopAtom);
    const { resetNavigation } = useNavigationHandler();

    const locales = useAllLocales();

    return (
        <nav className="relative z-40 w-full max-w-full">
            <div className="flex h-16 w-screen items-center justify-center bg-white shadow">
                {/*inner Grid*/}
                <div className="relative z-40 grid h-full w-full max-w-6xl grid-cols-[50px,auto,50px] gap-4 md:grid-cols-[200px,auto,auto]">
                    {/*Logo*/}
                    <div className="relative flex h-full w-full max-w-[150px] items-end justify-start py-2">
                        <div className="hidden h-full max-h-10 w-full md:block">
                            <LogoTypoSmall />
                        </div>
                        <div className="flex h-full w-full md:hidden">
                            <LogoBalloon />
                        </div>
                    </div>

                    {/* Middlepart = Buttons + Searchbar */}
                    <div className="relative flex w-full flex-row items-end gap-2">
                        {/*Buttons Shop & Topic*/}
                        <div className="relative flex h-full w-full items-end justify-start gap-6 pb-2  md:gap-10">
                            <button
                                className="text-sm font-bold uppercase text-primary antialiased md:text-base"
                                onClick={() => {
                                    resetNavigation();

                                    setShopNavigationState({
                                        ...shopNavigationState,
                                        showShop: !shopNavigationState.showShop,
                                        showTopics: false,
                                    });
                                }}
                            >
                                {locales?.['LL_General_Header_Shop'] ?? 'Shop'}
                            </button>
                            <button
                                className="text-sm font-bold uppercase text-primary antialiased md:text-base"
                                onClick={() => {
                                    resetNavigation();

                                    setShopNavigationState({
                                        ...shopNavigationState,
                                        showTopics: !shopNavigationState.showTopics,
                                        showShop: false,
                                    });
                                }}
                            >
                                {locales?.['LL_General_Header_Topics'] ?? 'Topics'}
                            </button>
                        </div>
                        <div className="relative flex h-14 w-full flex-row justify-center">
                            <SearchBarModern />
                        </div>
                    </div>

                    <div className="relative flex flex-row flex-nowrap gap-2">
                        <div className="relative flex h-full flex-row items-center gap-2 pt-3">
                            <FavouriteButton />
                            <ShoppingCartButton />
                        </div>
                        <UserAvatar />
                    </div>
                </div>
            </div>
        </nav>
    );
};

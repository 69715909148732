import React, { useCallback } from 'react';
import { languageListAvailable } from '../../helper/languageLists';
import { nanoid } from 'nanoid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FlagSymbol } from '../atoms/FlagSymbol/FlagSymbol';
import { useRecoilState } from 'recoil';
import { userContextStateRecoil } from './recoil/userContextState';
import { useUserChangeLanguage } from '../../hooks/api/gql/user/mutations/useUserChangeLanguage';
import { blockScreenStateRecoil } from '../atoms/Blockscreen/recoil/blockscreenState';
import { ubootStateRecoil } from '../atoms/UbootIframe/recoil/ubootState';
import { useAllLocales } from '../../hooks/locale/useAllLocales';

interface IUserContextLanguageSelector {
    currentLanguage: string;
}

export const UserContextLanguageSelector: React.FC<IUserContextLanguageSelector> = (props) => {
    const { currentLanguage } = props;
    const [userContextState, setUserContextState] = useRecoilState(userContextStateRecoil);
    const locales = useAllLocales();

    const { mutate: changeLanguageMutation } = useUserChangeLanguage();

    const [blockScreenState, setBlockScreenState] = useRecoilState(blockScreenStateRecoil);

    const [ubootState] = useRecoilState(ubootStateRecoil);

    const changeLanguage = useCallback(
        (newLanguage: string) => {
            setBlockScreenState({ ...blockScreenState, show: true });

            changeLanguageMutation(
                { language: newLanguage },
                {
                    onSuccess: () => {
                        setUserContextState({ ...userContextState, view: 'default' });
                        //damit die iframes auch was davon haben
                        // window.location.reload();

                        setBlockScreenState({ ...blockScreenState, show: false });

                        // Doesn't work on localhost?
                        ubootState.ref?.contentWindow?.location.reload();
                    },
                }
            );
        },
        [
            blockScreenState,
            changeLanguageMutation,
            setBlockScreenState,
            setUserContextState,
            ubootState.ref?.contentWindow?.location,
            userContextState,
        ]
    );

    return (
        <>
            <section className="relative grid w-full grid-cols-[3fr,3fr,3fr] gap-x-10 gap-y-2">
                {languageListAvailable.map((language) => {
                    return (
                        <button
                            key={nanoid()}
                            className="group relative w-full pl-10"
                            onClick={() => {}}
                        >
                            {currentLanguage === language && (
                                <div className="absolute top-1/3 -left-2 h-12 w-12 ">
                                    <FontAwesomeIcon
                                        icon={faChevronRight}
                                        className="animate-pulse text-2xl text-white"
                                    />
                                </div>
                            )}

                            <div className="absolute top-1/3 -left-2 h-12 w-12 opacity-0 group-hover:opacity-100 ">
                                <FontAwesomeIcon
                                    icon={faChevronRight}
                                    className="animate-pulse text-2xl text-primary-light"
                                />
                            </div>

                            <div
                                className="flex flex-row flex-nowrap gap-2"
                                onClick={() => changeLanguage(language)}
                            >
                                <div className="relative top-2 flex aspect-[4/3] h-8 items-start justify-start">
                                    <FlagSymbol code={language} />
                                </div>
                                {locales && (
                                    <span className="flex h-full h-12 items-center text-sm text-white opacity-75 transition-all group-hover:opacity-100">
                                        {locales[`LL_Sprache_${language}`]}
                                    </span>
                                )}
                            </div>
                        </button>
                    );
                })}
            </section>
        </>
    );
};

import { useApi } from '../../useApi';
import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';

export interface INavigationColumnItem {
    label: string;
    categoryID: number | null;
    mainCategory: boolean | null;
    link: string | null;
    tooltip: boolean | null;
    tooltipText: string | null;
    highlight: boolean | null;
}

interface INavigation {
    tabs: {
        headline: string;
        image: string;
        label: string;
        copyText: string;
        title: string;
        columns: {
            items: INavigationColumnItem[];
        }[];
    }[];
}

export const useNavigation = () => {
    const { postGql } = useApi();

    return useQuery(
        ['navigation'],
        async () => {
            const { navigation } = await postGql<{ navigation: INavigation }>(
                gql`
                    query {
                        navigation {
                            tabs {
                                headline
                                image
                                title
                                copyText
                                label
                                columns {
                                    items {
                                        label
                                        categoryID
                                        highlight
                                        link
                                        mainCategory
                                        tooltip
                                        tooltipText
                                    }
                                }
                            }
                        }
                    }
                `
            );
            return navigation;
        },
        { staleTime: 60000 }
    );
};

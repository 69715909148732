import React, { useMemo } from 'react';
import { getBasePath } from '../../../helper/getBasePath';

interface IFlagSymbol {
    code: string;
}

export const FlagSymbol: React.FC<IFlagSymbol> = (props) => {
    const { code } = props;

    const countryCode = useMemo(() => {
        if (code && code.length === 2) {
            return code;
        }

        return code?.split('-')[1] ?? 'GB';
    }, [code]);

    return (
        <>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
                className={`relative aspect-[4/3] h-full h-max transition-opacity`}
                src={`${getBasePath()}/flags/4x3/${countryCode.toLowerCase()}.svg`}
                alt={code ?? 'en-GB'}
            />
        </>
    );
};

import { useApi } from '../../useApi';
import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { IProduct } from '../../../../interfaces/product/product';

export const useProductsAll = () => {
    const { postGql } = useApi();

    return useQuery(
        ['products', 'all'],
        async () => {
            const { products } = await postGql<{ products: IProduct[] }>(
                gql`
                    query {
                        products(skip: 0, take: 1000) {
                            id
                            code
                            name
                            translation
                            thumbnail
                            pictures
                            imageBar
                            type
                            fields {
                                allowPDFOrder
                                downloadFile
                                endOfLife
                                endOfLifeReplacement
                                eyeCatcherColour
                                wawiArtikelnummer
                                eyeCatcherText
                                isCollection
                                isCommercial
                                isStaticProduct
                                orderPeriodExpired
                                outOfStock
                            }
                            category {
                                id
                            }
                            discount
                            favourite
                            specification {
                                description
                                relatedProducts {
                                    id
                                    name
                                    translation
                                    thumbnail
                                }
                                text
                                variable {
                                    text
                                    headline
                                }
                            }
                            visible
                        }
                    }
                `
            );
            return products;
        },
        { staleTime: 60000 }
    );
};

import React, { PropsWithChildren } from 'react';

interface IIWIContentBox extends PropsWithChildren {
    title: string;
    step?: number;
}

export const IWIContentBox: React.FC<IIWIContentBox> = (props) => {
    const { title, children, step } = props;

    return (
        <div className="relative flex w-full flex-col gap-2">
            <div className="mt-2 flex flex-row border-b-2 border-neutral-100 text-lg uppercase text-neutral-500">
                {step && <span className="mr-2 text-primary">{step}.</span>}
                <span>{title}</span>
            </div>
            <div className="relative flex w-full">{children}</div>
        </div>
    );
};

import React, { useMemo } from 'react';
import { Button } from '../../../atoms/Buttons/Button';
import { faChevronRight, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { useRecoilState, useRecoilValue } from 'recoil';
import { iwiStateTransparentRecoil } from '../../../IWI/recoil/iwiStateTransparent';
import { useAllLocales } from '../../../../hooks/locale/useAllLocales';

export interface IBottomNavigation {
    stages: number;
    currentStage: number;
    previousStage: () => void;
    nextStage: () => void;
    isSubmitting: boolean;
}

export const BottomNavigation: React.FC<IBottomNavigation> = (props) => {
    const { currentStage, previousStage, nextStage, isSubmitting } = props;

    const [iwiState] = useRecoilState(iwiStateTransparentRecoil);

    const { image } = useRecoilValue(iwiStateTransparentRecoil);

    const disabled = useMemo(() => {
        if (isSubmitting) return true;

        if (currentStage === 4 && !image) return true;
        return currentStage === 5 && (!image || !iwiState.DSVGO_Contract);
    }, [currentStage, image, isSubmitting, iwiState.DSVGO_Contract]);

    const colorNext = useMemo(() => {
        if (disabled) {
            return 'default';
        }
        if (currentStage === 5) {
            return 'success';
        }
        return 'primary';
    }, [currentStage, disabled]);

    const locales = useAllLocales();

    return (
        <div
            className={`relative flex w-full flex-row ${
                currentStage === 1 ? 'justify-end' : 'justify-between'
            }`}
        >
            {currentStage >= 2 && (
                <div className="relative w-max">
                    <Button height="h-8" onClick={() => previousStage()} addtionalCss="px-7">
                        {locales?.['LL_OnBoarding_Popup_Step_Prev'] ?? 'Back'}
                    </Button>
                </div>
            )}
            <div className="relative w-max">
                <Button
                    onClick={() => nextStage()}
                    disabled={disabled}
                    color={disabled ? 'default' : colorNext}
                    height="h-8"
                    iconEnd={currentStage === 5 ? undefined : faChevronRight}
                    iconStart={!isSubmitting ? undefined : faRefresh}
                    iconSpin={isSubmitting}
                    addtionalCss={` ${currentStage === 5 ? 'px-11' : 'px-9'}`}
                >
                    {currentStage === 5
                        ? locales?.['LL_OnBoarding_Popup_Step_Send'] ?? 'Send'
                        : locales?.['LL_OnBoarding_Popup_Step_Next'] ?? 'Next'}
                </Button>
            </div>
        </div>
    );
};

import React from 'react';

export interface IProfileHeadline {
    headline: string;
    type: 'h1' | 'h2';
}

export const ProfileHeadline: React.FC<IProfileHeadline> = (props) => {
    const { headline, type } = props;

    return (
        <>
            {type === 'h1' && (
                <h1 className="break-words text-[32px] font-bold text-primary">{headline}</h1>
            )}
            {type === 'h2' && (
                <h4 className="break-words text-xl font-bold uppercase leading-6 text-primary">
                    {headline}
                </h4>
            )}
        </>
    );
};

import React, { useCallback, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { navigationShopAtom } from './recoil/shopNavigation';
import ModalFullscreen from '../Modal/ModalFullscreen';
import Image from 'next/legacy/image';
import { getBasePath, withHostPathAbsolute } from '../../helper/getBasePath';
import Link from 'next/link';
import { Transition } from '@headlessui/react';
import { useAllLocales } from '../../hooks/locale/useAllLocales';

interface ITopic {
    image: string;
    label: string;
    href: string;
}

export const TopicsNavigation: React.FC = () => {
    const [navState, setNavState] = useRecoilState(navigationShopAtom);

    const locales = useAllLocales();

    const topics = useMemo((): ITopic[] => {
        if (!locales) return [];

        return [
            {
                // label: 'Mein RE/MAX Office',
                label: locales['LL_Themen_Sidebar_MyREMAXOffice'] ?? '',
                image: '/images/header-topics/RE-EU-BTB-Office_Design.svg',
                href: withHostPathAbsolute('content.php?categoryCode=MyREMAXOffice'),
            },
            {
                label: locales['LL_Header_Themen_Objectvermarktung'] ?? '',
                image: '/images/header-topics/RE-EU-BTB-Property_Marketing.svg',
                href: withHostPathAbsolute('content.php?categoryCode=Objektvermarktung'),
            },
            {
                label: locales['LL_Header_Themen_SelfPromotion'] ?? '',
                image: '/images/header-topics/RE-EU-BTB-Self_promotion.svg',
                href: withHostPathAbsolute('content.php?categoryCode=Eigenwerbung'),
            },
            {
                label: locales['LL_AgentOnb_Kategorie_Navigation_Copy'] ?? '',
                image: '/images/header-topics/compass-image.svg',
                href: withHostPathAbsolute('content.php?categoryCode=AgentOnboarding'),
            },
        ];
    }, [locales]);

    const getDelay = useCallback((index: number): string => {
        switch (index) {
            case 0:
                return 'delay-100';
            case 1:
                return 'delay-300';
            case 2:
                return 'delay-500';
            case 3:
                return 'delay-700';
            case 4:
                return 'delay-1000';
        }

        return 'delay-1000';
    }, []);

    return (
        <>
            <ModalFullscreen
                show={navState.showTopics}
                closeFunction={() => setNavState({ ...navState, showTopics: false })}
            >
                <section className="relative mx-auto mt-8 flex w-full max-w-7xl flex-row gap-6 ">
                    {topics.map((item, index) => {
                        return (
                            <Transition
                                show={true}
                                appear={true}
                                key={`topic${index}`}
                                enter={`transition ease-out duration-1000 ${getDelay(index)}`}
                                enterFrom="opacity-0 translate-y-5"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in duration-150"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 translate-y-1"
                            >
                                <Link
                                    href={item.href}
                                    className="flex w-full flex-col gap-3 transition-all hover:scale-105"
                                >
                                    <figure className="relative flex aspect-[1/1] h-60 w-60 bg-white">
                                        <Image
                                            src={getBasePath() + item.image}
                                            layout={'fill'}
                                            objectFit={'fill'}
                                            alt={item.label}
                                        />
                                    </figure>
                                    <label className="text-xl text-white">{item.label}</label>
                                </Link>
                            </Transition>
                        );
                    })}
                </section>
            </ModalFullscreen>
        </>
    );
};

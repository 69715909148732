import React from 'react';
import { getBasePath } from '../../../helper/getBasePath';

export const LogoBalloon: React.FC = () => {
    return (
        <>
            <figure aria-hidden={true} className="relative flex h-full w-min flex-col">
                <div className="relative flex aspect-[1/1] h-full items-start justify-start">
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <img
                        src={`${getBasePath()}/remaxBalloon.svg`}
                        alt="RE/MAX Balloon"
                        className="relative h-full"
                    />
                </div>
            </figure>
        </>
    );
};

import { useApi } from '../../useApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { IProduct } from '../../../../interfaces/product/product';

export const useProductRemoveFavourite = () => {
    const { postGql } = useApi();

    const queryClient = useQueryClient();

    return useMutation(
        async (input: { id: number }) => {
            const { unfavouriteProduct } = await postGql<{ unfavouriteProduct: IProduct }>(
                gql`
                    mutation ($input: UpdateFavouriteInput!) {
                        unfavouriteProduct(input: $input) {
                            id
                            code
                            name
                            translation
                            thumbnail
                            pictures
                            imageBar
                            type
                            fields {
                                allowPDFOrder
                                downloadFile
                                endOfLife
                                endOfLifeReplacement
                                eyeCatcherColour
                                wawiArtikelnummer
                                eyeCatcherText
                                isCollection
                                isCommercial
                                isStaticProduct
                                orderPeriodExpired
                                outOfStock
                            }
                            category {
                                id
                            }
                            priceTable {
                                id
                                name
                                keys {
                                    field
                                    values {
                                        name
                                        value
                                        quantities {
                                            price
                                            quantity
                                            weight
                                        }
                                    }
                                }
                            }
                            discount
                            favourite
                            specification {
                                description
                                relatedProducts {
                                    id
                                    name
                                    translation
                                    thumbnail
                                }
                                text
                                variable {
                                    text
                                    headline
                                }
                            }
                            visible
                        }
                    }
                `,
                { input }
            );
            return unfavouriteProduct;
        },
        {
            onSuccess: (product) => {
                void queryClient.setQueryData(['product', product.id], product);
                void queryClient.setQueryData(['products', 'all'], (current?: IProduct[]) => {
                    let temp: IProduct[] = [];
                    if (current) {
                        temp = [...current];
                        const index = temp.findIndex((tempProd) => tempProd.id === product.id);

                        if (index && temp[index]) {
                            temp[index] = product;
                        }
                    }
                    return temp;
                });
            },
        }
    );
};

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import Link from 'next/link';
import { Badge } from '../atoms/Badges/Badge';
import { useShoppingCartCount } from '../../hooks/api/gql/shoppingCart/queries/useShoppingCartCount';
import { withHostPathAbsolute } from '../../helper/getBasePath';
import { useNavigationHandler } from '../Header/hooks/useNavigationHandler';
import { useSetRecoilState } from 'recoil';
import { navigationShopAtom } from '../Header/recoil/shopNavigation';

export const ShoppingCartButton: React.FC = () => {
    const { data: scCount } = useShoppingCartCount();

    const setShopNavigationState = useSetRecoilState(navigationShopAtom);

    const { resetNavigation } = useNavigationHandler();

    return (
        <>
            <Link
                href={withHostPathAbsolute('shop/cart?section=cart')}
                className="group relative flex aspect-[1/1] h-10 w-10 cursor-pointer items-center justify-center rounded-full transition-all hover:bg-neutral-100"
                onClick={() => {
                    resetNavigation();
                    setShopNavigationState((currVal) => {
                        return {
                            ...currVal,
                            showShop: false,
                            showTopics: false,
                        };
                    });
                }}
            >
                <FontAwesomeIcon icon={faCartShopping} className="z-10 text-xl text-neutral-400" />
                <div className="absolute -top-2 -right-2">
                    <Badge>{(scCount && scCount.inCart) ?? 0}</Badge>
                </div>
            </Link>
        </>
    );
};

import { useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useApi } from '../../useApi';

interface IWIInput {
    firstName: string;
    lastName: string;
    email: string;
    DSVGO_Contract: string;
    fileName: string;
}

export const useUploadTransparentIWI = () => {
    const { postUpload } = useApi();

    return useMutation(async (inputLocal: { input: IWIInput; file: File }) => {
        const { uploadTransparentIWI } = await postUpload<{
            uploadTransparentIWI: { success: boolean; errorText?: string };
        }>(
            gql`
                mutation ($input: IWIUploadArgs!, $file: Upload!) {
                    uploadTransparentIWI(file: $file, input: $input) {
                        success
                        errorText
                    }
                }
            `,
            { input: inputLocal.input, file: null },
            { '0': ['variables.file'] },
            [inputLocal.file]
        );
        return uploadTransparentIWI;
    });
};

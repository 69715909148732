import { useUser } from '../api/gql/user/queries/useUser';
import { usePathname } from 'next/navigation';

export const useUserLanguageFromServer = (): string | null => {
    const pathname = usePathname();

    const { data: user } = useUser(
        !pathname?.startsWith('/login') &&
            !pathname?.startsWith('/register') &&
            !pathname?.startsWith('/forgotpw') &&
            !pathname?.startsWith('/content')
    );

    if (!user && pathname?.startsWith('/content')) {
        return 'en';
    }

    if (user && user.language) return user.language.split('-')[0] as string;

    return null;
};

import React from 'react';
import { Avatar } from '../atoms/Avatar/Avatar';
import { useRecoilState } from 'recoil';
import { userContextStateRecoil } from './recoil/userContextState';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useNavigationHandler } from '../Header/hooks/useNavigationHandler';
import { useAllLocales } from '../../hooks/locale/useAllLocales';
import { useUser } from '../../hooks/api/gql/user/queries/useUser';

export const UserAvatar: React.FC = () => {
    const { data: userData } = useUser();
    const [userContextState, setUserContextState] = useRecoilState(userContextStateRecoil);

    const { resetNavigation } = useNavigationHandler();

    const locales = useAllLocales();

    return (
        <>
            {userData && userData.fields && (
                <div className="relative flex w-full items-end justify-end gap-2 pb-2">
                    <button
                        className="group relative hidden flex-col items-end justify-center text-sm leading-tight md:flex"
                        onClick={() => {
                            resetNavigation();

                            setUserContextState({
                                ...userContextState,
                                showModal: !userContextState.showModal,
                            });
                        }}
                    >
                        <div className="absolute -left-6 top-[1px] opacity-50 transition-all group-hover:opacity-100">
                            <FontAwesomeIcon
                                icon={faChevronDown}
                                className="text-base text-primary"
                            />
                        </div>
                        <span className="text-primary">
                            {userData.fields['UserProfileFirstName']?.substring(0, 1)}.{' '}
                            {userData.fields['UserProfileLastName']}
                        </span>
                        <span className="text-neutral-500">
                            {(locales && locales['LL_Header_Menu']) ?? 'Menu'}
                            {/*{userData.fields['UserProfileFunktion1']}*/}
                        </span>
                    </button>
                    <button
                        className="relative top-2 flex h-14 w-14 items-center justify-center p-1 "
                        onClick={() => {
                            setUserContextState({
                                ...userContextState,
                                showModal: !userContextState.showModal,
                            });
                        }}
                    >
                        <Avatar image={userData.thumbnail ?? ''} isUser={true} />
                    </button>
                </div>
            )}
        </>
    );
};

import { useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useApi } from '../../../useApi';

export const useClearUserCache = () => {
    const { postGql } = useApi();

    return useMutation(async () => {
        const { clearUserCache } = await postGql<{ clearUserCache: boolean }>(
            gql`
                mutation {
                    clearUserCache
                }
            `,
            undefined,
            `clearUserCache`
        );
        return clearUserCache;
    });
};

import React from 'react';
import { ModalFullscreenNeutral } from '../atoms/Modal/ModalFullscreenNeutral';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { contactFormStateRecoil, getContactFormShow } from './state/contactFormState';
import { useUserLanguageFromServer } from '../../hooks/locale/useUserLanguage';

export interface IContactFormLegacy {}

export const ContactFormLegacy: React.FC<IContactFormLegacy> = () => {
    const setContactFormState = useSetRecoilState(contactFormStateRecoil);
    const showContactForm = useRecoilValue(getContactFormShow);

    const language = useUserLanguageFromServer();

    return (
        <>
            <ModalFullscreenNeutral
                show={showContactForm}
                closeable={true}
                afterClose={() =>
                    setContactFormState((currVal) => {
                        return { ...currVal, show: false };
                    })
                }
                noPadding={true}
            >
                <div className="relative flex max-h-[800px] w-full">
                    <iframe
                        src={`/shop/contactform/${language ?? 'en'}?session=1`}
                        className={'h-[680px] w-[1400px]'}
                    />
                </div>
            </ModalFullscreenNeutral>
        </>
    );
};

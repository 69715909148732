import { FC, PropsWithChildren } from 'react';
import { useRecoilValue } from 'recoil';
import { apiState } from '../../recoil/request';
import { useUser } from '../../hooks/api/gql/user/queries/useUser';
import { addIFramed } from '../../helper/addIFramed';

export const AuthGuard: FC<PropsWithChildren> = ({ children }) => {
    useUser();

    const { refreshSrc } = useRecoilValue(apiState);

    return (
        <>
            <iframe className="hidden" src={addIFramed(refreshSrc)} />
            {children}
        </>
    );
};

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import 'swiper/css';
import { toast } from 'react-toastify';
import { ModalFullscreenNeutral } from '../atoms/Modal/ModalFullscreenNeutral';
import { useAllLocales } from '../../hooks/locale/useAllLocales';
import { useUser } from '../../hooks/api/gql/user/queries/useUser';
import { iwiStateTransparentRecoil } from './recoil/iwiStateTransparent';
import { useUploadTransparentIWI } from '../../hooks/api/gql/iwi/useUploadTransparentIWI';
import { TransparentPortrait } from '../Profile/transparentPortrait/TransparentPortrait';
import dayjs from 'dayjs';

export const IWIModalTransparent: React.FC = () => {
    const { data: userData } = useUser();
    const [iwiState, setIwiState] = useRecoilState(iwiStateTransparentRecoil);

    const locales = useAllLocales();

    const [currentSlider, setCurrentSlider] = useState(1);

    const { mutate: uploadIwi, isLoading } = useUploadTransparentIWI();

    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        const text = `${locales?.['RMC_IWI_AV_Copy_1'] ?? ''}${
            userData?.fields['UserProfileFirstName'] ?? ''
        } ${userData?.fields['UserProfileLastName'] ?? ''} ${
            userData?.fields['UserProfileAddress1'] ?? ''
        } ${dayjs().format('DD.MM.YYYY HH:mm')} ${locales?.['RMC_IWI_AV_Copy_2'] ?? ''} ${
            locales?.['RMC_IWI_AV_Copy_3'] ?? ''
        } ${locales?.['RMC_IWI_AV_Copy_4'] ?? ''} ${locales?.['RMC_IWI_AV_Copy_5'] ?? ''} ${
            locales?.['RMC_IWI_AV_Copy_6'] ?? ''
        }`;

        if (!iwiState.DSVGO_Contract) {
            setIwiState((currVal) => {
                return { ...currVal, DSVGO_Contract: text };
            });
        }
    }, [iwiState.DSVGO_Contract, locales, setIwiState, userData?.fields]);

    const submit = useCallback(() => {
        if (iwiState.image && iwiState.DSVGO_Contract) {
            uploadIwi(
                {
                    input: {
                        firstName: iwiState.firstname,
                        lastName: iwiState.lastname,
                        email: iwiState.email,
                        fileName: iwiState.image?.name ?? '',
                        DSVGO_Contract: iwiState.DSVGO_Contract,
                    },
                    file: iwiState.image,
                },
                {
                    onSuccess: () => {
                        toast.success(locales?.['LL_IWI_Upload_Success'] ?? 'Upload successful', {
                            toastId: 'iwi-success',
                        });

                        // Reset State
                        setIwiState({
                            show: false,
                            image: null,
                            lastname: '',
                            firstname: '',
                            email: '',
                            DSVGO_Contract: null,
                        });

                        // Reset Slider
                        setCurrentSlider(1);

                        // Clear value
                        const inp = inputRef.current as HTMLInputElement;
                        if (inp) {
                            inp.value = '';
                        }
                    },
                    onError: (e) => {
                        // eslint-disable-next-line no-console
                        console.log('E', e);

                        // Default Fehler
                        toast.error(locales?.['LL_IWI_Upload_Fail_Error'] ?? 'Upload failed.', {
                            toastId: 'iwi-failed',
                        });
                    },
                }
            );
        } else {
            toast.error('Es wurde keine Datei angegeben', { toastId: 'iwi-failed' });
        }
    }, [
        iwiState.DSVGO_Contract,
        iwiState.email,
        iwiState.firstname,
        iwiState.image,
        iwiState.lastname,
        locales,
        setIwiState,
        uploadIwi,
    ]);

    const swipeNext = useCallback(() => {
        if (currentSlider === 5) {
            submit();
        } else {
            setCurrentSlider(currentSlider + 1);
        }
    }, [currentSlider, submit]);

    const swipePrev = useCallback(() => {
        setCurrentSlider(currentSlider - 1);
    }, [currentSlider]);

    useEffect(() => {
        if (userData) {
            setIwiState((current) => {
                return {
                    ...current,
                    firstname: userData.fields['UserProfileFirstName'] ?? '',
                    lastname: userData.fields['UserProfileLastName'] ?? '',
                    email: userData.email ?? '',
                };
            });
        }
    }, [setIwiState, userData]);

    useEffect(() => {
        if (iwiState.image) {
            setCurrentSlider(5);
        }
    }, [iwiState.image]);

    return (
        <>
            <ModalFullscreenNeutral
                show={iwiState.show}
                closeable={true}
                afterClose={() => setIwiState({ ...iwiState, show: false })}
            >
                <TransparentPortrait
                    stages={5}
                    currentStage={currentSlider}
                    nextStage={() => swipeNext()}
                    previousStage={() => swipePrev()}
                    isSubmitting={isLoading}
                />
            </ModalFullscreenNeutral>
        </>
    );
};

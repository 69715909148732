import React from 'react';
import ModalFullscreen from '../Modal/ModalFullscreen';
import { useRecoilState } from 'recoil';
import { favouriteStateRecoil } from './recoil/favouriteState';
import { SkeletonProduct } from '../Skeletons/SkeletonProduct';
import { ProductDisplay } from '../ProductList/ProductDisplay';
import { useFavouriteProducts } from '../../hooks/useFavouriteProducts';
import { useAllLocales } from '../../hooks/locale/useAllLocales';

export const FavouritesModal: React.FC = () => {
    const [favouriteState, setFavouriteState] = useRecoilState(favouriteStateRecoil);

    const locales = useAllLocales();

    const { favourites, isLoading } = useFavouriteProducts();

    return (
        <>
            <ModalFullscreen
                show={favouriteState.showModal}
                closeFunction={() => setFavouriteState({ ...favouriteState, showModal: false })}
            >
                <section className="relative mx-auto mt-8 flex w-full max-w-6xl flex-col gap-6">
                    {locales && (
                        <h1 className="text-4xl text-white">{locales['LL_Favorites_Headline']}</h1>
                    )}

                    <div className="relative flex w-full flex-row flex-wrap gap-8">
                        {isLoading && (
                            <>
                                <SkeletonProduct />
                                <SkeletonProduct />
                                <SkeletonProduct />
                                <SkeletonProduct />
                                <SkeletonProduct />
                                <SkeletonProduct />
                                <SkeletonProduct />
                            </>
                        )}

                        {!isLoading &&
                            favourites &&
                            favourites.map((product) => {
                                return (
                                    <ProductDisplay
                                        product={product}
                                        color="white"
                                        key={`product-fav${product.id}`}
                                    />
                                );
                            })}
                    </div>
                </section>
            </ModalFullscreen>
        </>
    );
};

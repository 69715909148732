import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

interface ICloseButton {
    action: () => void;
    color?: 'default' | 'primary';
}

export const CloseButton: React.FC<ICloseButton> = (props) => {
    const { color, action } = props;

    const colorUsed = useMemo(() => {
        switch (color) {
            case 'primary':
                return 'text-primary';
            default:
                return 'text-neutral-50/50';
        }
    }, [color]);

    return (
        <>
            <button>
                <FontAwesomeIcon
                    icon={faXmark}
                    className={`text-4xl ${colorUsed}`}
                    onClick={() => action()}
                />
            </button>
        </>
    );
};

CloseButton.defaultProps = {
    color: 'default',
};

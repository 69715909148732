import React from 'react';
import { useNavigation } from '../../hooks/api/gql/navigation/useNavigation';
import { nanoid } from 'nanoid';
import { SubHeaderButton } from './SubHeaderButton';

interface ISubHeader {
    show: boolean;
}

export const SubHeader: React.FC<ISubHeader> = (props) => {
    const { show } = props;

    const { data: navigation } = useNavigation();

    return (
        <>
            {show && (
                <>
                    <nav className="relative flex h-8 w-full bg-neutral-50">
                        <div className="mx-auto flex w-full max-w-6xl flex-row flex-row items-center justify-start gap-10">
                            {navigation &&
                                navigation.tabs.map((item, index) => {
                                    return (
                                        <SubHeaderButton
                                            label={item.label}
                                            key={nanoid()}
                                            tab={index}
                                        />
                                    );
                                })}
                        </div>
                    </nav>
                </>
            )}
        </>
    );
};

import React, { PropsWithChildren, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { BackgroundBalloon } from '../atoms/BackgroundBalloon/BackgroundBalloon';
import { CloseButton } from '../atoms/CloseButton/CloseButton';
import { useKeyPress } from 'react-use';

interface IModalFullscreen extends PropsWithChildren {
    show: boolean;
    closeFunction: () => void;
}

export const ModalFullscreen: React.FC<IModalFullscreen> = (props) => {
    const { closeFunction, show } = props;

    const predicate = (event: KeyboardEvent) => event.key === 'Escape';
    const escPressed = useKeyPress(predicate);

    useEffect(() => {
        if (show && escPressed && escPressed[0]) {
            closeFunction();
        }
    }, [closeFunction, escPressed, show]);

    return (
        <>
            <Transition
                className="fixed top-[66px] z-[500] h-[calc(100vh-4em)] w-screen overflow-hidden"
                show={show ?? false}
                appear={show ?? false}
                enter="transition ease-out duration-500"
                enterFrom="transform translate-y-4 opacity-50"
                enterTo="transform translate-y-0 opacity-100"
                leave="transition ease-in duration-200"
                leaveFrom="transform opacity-100 translate-y-0"
                leaveTo="transform opacity-0 translate-y-4"
            >
                <section className="relative left-0 top-0 flex h-screen w-screen overflow-x-hidden bg-blue-900">
                    <BackgroundBalloon />

                    {/*Close button*/}
                    <div className="absolute right-2 top-2 z-40 h-10 w-10">
                        <CloseButton action={() => closeFunction()} />
                    </div>

                    {/*Inner Wrapper*/}
                    <div className="relative h-full w-full overflow-y-auto ">{props.children}</div>
                </section>
            </Transition>
        </>
    );
};
export default ModalFullscreen;

import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { nanoid } from 'nanoid';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { regexMobile } from '../../regex/regexMobile';
import { useToggle } from 'react-use';
import { RmcLabel } from '../ProfileLabel/RmcLabel';

export interface ITextFieldNew {
    id?: string;
    testId?: string;
    type?: 'password' | 'text' | 'number';
    required?: boolean;
    tag?: string;
    label?: string;
    labelDescription?: string;
    placeholder?: string;
    autocomplete?: string;
    defaultValue?: string;
    onChange?: (value: string) => void;
    onBlur?: (value: string) => void;
    manipulation?: (value: string) => string;
    iconStart?: IconDefinition;
    iconEnd?: IconDefinition;
    iconStartOpen?: ReactElement<unknown>;
    iconSpin?: boolean;
    validation?: 'email' | 'url' | 'number' | 'phone' | 'custom' | 'password' | 'mobile';
    validationRegEx?: RegExp;
    validationMessage?: string;
    showValidation?: boolean;
    reportValidation?: (id: string, isValid: boolean) => void;
    alignWithFormFields?: boolean;
    disabled?: boolean;
    inputClassName?: string;
}

interface IValidation {
    isValid: boolean;
    message: string;
}

export const TextFieldNew: React.FC<ITextFieldNew> = (props) => {
    const {
        id,
        testId,
        type,
        required,
        tag,
        label,
        labelDescription,
        placeholder,
        autocomplete,
        defaultValue,
        onChange,
        onBlur,
        iconStart,
        iconEnd,
        iconSpin,
        iconStartOpen,
        validation,
        validationRegEx,
        validationMessage,
        reportValidation,
        showValidation,
        manipulation,
        alignWithFormFields,
        disabled,
        inputClassName,
    } = props;

    const [value, setValue] = useState<string | null>(null);

    // const [showPassword, setShowPassword] = useState(true);

    const [showPassword, toggleShowPassword] = useToggle(false);

    const regexEMail = useMemo(() => {
        return /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;
    }, []);

    const regexUrl = useMemo(() => {
        return /https?:\/\/(?:www\.)?([-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b)*(\/[\\/\w.-]*)*[\\?]*(.+)*/;
    }, []);

    const regexInteger = useMemo(() => {
        return /^\d+$/g;
    }, []);

    const regexPhone = useMemo(() => {
        return /\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/;
    }, []);

    const forbiddenChars = useMemo((): string[] => {
        return ['<', '>', '\\', '{', '}'];
    }, []);

    const validationState = useMemo((): IValidation => {
        // Check for böse
        if (type !== 'password') {
            let forbidden: IValidation | null = null;
            forbiddenChars.forEach((forbiddenItem) => {
                if (value && value.includes(forbiddenItem)) {
                    const chars = forbiddenChars.join('  ');

                    forbidden = {
                        isValid: false,
                        message: `Forbidden characters ${chars}`,
                    };
                }
            });
            if (forbidden) return forbidden;
        }

        // Check for required
        if (required && (value === '' || !value)) {
            return {
                isValid: false,
                message: validationMessage ?? 'Field is required',
            };
        }

        // Actual validation
        if ((value !== null && !required && value !== '') || required) {
            switch (validation) {
                case 'email':
                    if (!regexEMail.test(value as string)) {
                        return {
                            isValid: false,
                            message:
                                validationMessage ??
                                'Please enter a valid email address, e. g. yourname@example.com',
                        };
                    }
                    break;
                case 'phone':
                    if (!regexPhone.test(value as string)) {
                        return {
                            isValid: false,
                            message: validationMessage ?? 'Use this format: +XX XXXX XXXXXX',
                        };
                    }
                    break;
                case 'mobile':
                    if (!regexMobile.test(value as string)) {
                        return {
                            isValid: false,
                            message: validationMessage ?? 'Use this format: +XXXXXXXXXXXX',
                        };
                    }
                    break;
                case 'number':
                    if (!regexInteger.test(value as string)) {
                        return {
                            isValid: false,
                            message: validationMessage ?? 'Only integer allowed',
                        };
                    }
                    break;
                case 'url':
                    if (!regexUrl.test(value as string)) {
                        return {
                            isValid: false,
                            message: validationMessage ?? "Doesn't look like an URL",
                        };
                    } else {
                        if (value && !value.trim().toLowerCase().startsWith('https://')) {
                            return {
                                isValid: false,
                                message: 'URL must start with https (SSL)',
                            };
                        }
                    }
                    break;
                case 'custom':
                    if (validationRegEx && !validationRegEx.test(value as string)) {
                        return {
                            isValid: false,
                            message: validationMessage ?? "Doesn't look like an e-mail",
                        };
                    }
                    break;
            }
        }

        return {
            isValid: true,
            message: '',
        };
    }, [
        forbiddenChars,
        regexEMail,
        regexInteger,
        regexPhone,
        regexUrl,
        required,
        type,
        validation,
        validationMessage,
        validationRegEx,
        value,
    ]);

    useEffect(() => {
        if (reportValidation && id) {
            reportValidation(id, validationState.isValid ?? true);
        }
    }, [id, reportValidation, validationState]);

    const showLabelDescription = useMemo(() => {
        if (labelDescription && labelDescription !== '') {
            return !showValidation;
        }

        return false;
    }, [labelDescription, showValidation]);

    const validationStyles = useMemo(() => {
        return validationState.isValid || !showValidation
            ? 'border-[rgba(5,15,38,0.24)]'
            : 'border-danger';
    }, [showValidation, validationState.isValid]);

    const borderStyles = useMemo(() => {
        if (!validationState.isValid) return 'border-red-600';
        return 'border-neutral-300';
    }, [validationState.isValid]);

    const inputType = useMemo(() => {
        switch (type) {
            case 'password':
                return showPassword ? 'text' : 'password';
            default:
                return type;
        }
    }, [showPassword, type]);

    const inputWrapperGrid = useMemo(() => {
        if (iconStart) return 'grid grid-cols-[50px,auto]';

        return 'flex flex-col';
    }, [iconStart]);

    const textFieldText = useMemo(() => {
        // if (type === 'date') return 'text-primary font-light text-xs';

        return 'text-primary text-sm';
    }, []);

    const roundedStyles = useMemo(() => {
        if (iconStart) return 'rounded-tr rounded-br';

        return 'rounded';
    }, [iconStart]);

    useEffect(() => {
        setValue(defaultValue ?? '');
    }, [defaultValue]);

    return (
        <>
            <div className="relative flex w-full flex-col gap-0.5">
                {alignWithFormFields && required && <div className="relative block h-[25px]" />}
                {alignWithFormFields && !required && <div className="relative block h-6" />}

                {label && label !== '' && (
                    <RmcLabel required={required} tag={tag}>
                        {label}
                    </RmcLabel>
                )}

                {/*<div className="absolute right-0 top-0">*/}
                {/*    {type === 'password' && (*/}
                {/*        <FontAwesomeIcon*/}
                {/*            icon={showPassword ? faEye : faEyeSlash}*/}
                {/*            className="absolute right-1 bottom-0 text-base text-neutral-200"*/}
                {/*            onClick={() => toggleShowPassword()}*/}
                {/*        />*/}
                {/*    )}*/}
                {/*</div>*/}

                <div className={`${inputWrapperGrid} relative w-full gap-0`}>
                    {iconStartOpen && (
                        <div className="flex items-center justify-center rounded-tl rounded-bl bg-neutral-300">
                            {iconStartOpen}
                        </div>
                    )}
                    {iconStart && (
                        <div className="flex items-center justify-center rounded-tl rounded-bl bg-neutral-300">
                            <FontAwesomeIcon icon={iconStart} className="w-6 text-white" />
                        </div>
                    )}
                    <input
                        className={`relative h-10 w-full placeholder:text-body-light ${
                            inputClassName ?? ''
                        } ${roundedStyles} ${validationStyles} ${textFieldText} ${borderStyles} ${
                            disabled ? 'bg-neutral-100' : ''
                        }`}
                        type={inputType}
                        required={true}
                        value={value ?? ''}
                        placeholder={placeholder ?? ''}
                        autoComplete={autocomplete ?? 'off'}
                        onChange={(e) => {
                            const newValue = manipulation?.(e.target.value ?? '') ?? e.target.value;
                            setValue(newValue);
                            if (onChange) onChange(newValue);
                        }}
                        name={autocomplete}
                        id={id ?? undefined}
                        data-test-id={testId ?? nanoid()}
                        key={''}
                        onBlur={() => {
                            if (onBlur) {
                                onBlur(value ?? '');
                            }
                        }}
                        disabled={disabled}
                        readOnly={disabled}
                    />

                    {type === 'password' && (
                        <button
                            type="button"
                            aria-hidden={true}
                            className="absolute right-1 -top-6 flex items-center justify-center text-neutral-500/50"
                            onClick={() => toggleShowPassword()}
                        >
                            {!showPassword && <FontAwesomeIcon icon={faEyeSlash} />}
                            {showPassword && <FontAwesomeIcon icon={faEye} />}
                        </button>
                    )}

                    {iconEnd && (
                        <div className="absolute right-1 flex h-full w-max items-center justify-center">
                            <FontAwesomeIcon
                                icon={iconEnd}
                                className={`text-neutral-300 ${iconSpin ? 'animate-spin' : ''} ${
                                    validationState.isValid ? 'text-neutral-300' : 'text-danger'
                                }`}
                            />
                        </div>
                    )}

                    {/* Additional Description */}
                    {showLabelDescription && (
                        <div className="relative mt-1 flex flex-row gap-1">
                            <span className="text-sm text-body-light">{labelDescription}</span>
                        </div>
                    )}

                    {!validationState.isValid && showValidation && (
                        <div className="relative col-span-12 ml-1 mt-1 flex flex-row items-center gap-1 text-xs text-danger">
                            <FontAwesomeIcon
                                icon={faExclamationTriangle}
                                className="relative h-3 text-danger"
                            />
                            <span>{validationState.message}</span>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

TextFieldNew.defaultProps = {
    id: nanoid(),
    type: 'text',
    required: false,
    showValidation: false,
    iconSpin: false,
};

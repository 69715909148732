import { useMemo } from 'react';

export interface IFeatureFlagList {
    SHOW_XMAS: boolean;
}

export const useFeatureFlags = (): IFeatureFlagList | null => {
    const flags = useMemo((): IFeatureFlagList => {
        return {
            SHOW_XMAS: process.env['NEXT_PUBLIC_FLAG_SHOW_XMAS'] === '1' ?? false,
        };
    }, []);

    return flags ?? null;
};
